<template>
    <div :class="chartClass" ref="Chart">
        <div class="ChartsWrap" v-if="chartType === 'pie' && secondValues.length > 0">
            <div class="ChartContainer">
                <h2 v-if="title" v-html="title || '<br />'" />
                <apexchart
                    v-if="chartOptions && series.length"
                    :type="chartType"
                    :height="chartHeight"
                    :width="chartWidth"
                    :options="chartOptions"
                    :series="series"
                />
            </div>
            <div class="ChartContainer">
                <h2 v-if="title" v-html="secondValuesTitle || '<br />'" />
                <apexchart
                    v-if="secondChartOptions && secondSeries.length"
                    :type="chartType"
                    :height="chartHeight"
                    :width="chartWidth"
                    :options="secondChartOptions"
                    :series="secondSeries"
                />
            </div>
        </div>

        <div class="ChartsWrap" v-else-if="renderSeparately && secondValues.length > 0">
            <div class="ChartContainer">
                <h2 v-if="title" v-html="title || '<br />'" />
                <apexchart
                    v-if="chartOptions && series.length"
                    :type="chartType"
                    :height="chartHeight"
                    :width="chartWidth"
                    :options="chartOptions"
                    :series="series"
                />
            </div>
            <div class="ChartContainer">
                <h2 v-if="title" v-html="secondValuesTitle || '<br />'" />
                <apexchart
                    v-if="secondChartOptions && secondSeries.length"
                    :type="chartType"
                    :height="chartHeight"
                    :width="chartWidth"
                    :options="secondChartOptions"
                    :series="secondSeries"
                />
            </div>
        </div>

        <div v-else>
            <h2>
                {{ title }}
                <slot></slot>
            </h2>
            <apexchart
                v-if="chartOptions && series.length"
                :type="chartType"
                :height="chartHeight"
                :width="chartWidth"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { priceToEuroString } from '@/lib/helper';
import { format } from 'date-fns';

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        title: String,
        secondValuesTitle: String,
        values: {
            type: Array,
            default: () => [],
        },
        secondValues: {
            type: Array,
            default: () => [],
        },
        groupByService: {
            type: Boolean,
            default: false,
        },
        activeBar: Number,
        type: {
            type: String,
            default: 'bar', // bar by default, but can also be "pie"
        },
        halfHeight: Boolean,
        halfWidth: Boolean,
        quarterHeight: Boolean,
        threeForthHeight: Boolean,
        color: String,
        index: String,
        isSticky: Boolean,
        direction: {
            type: String,
            default: 'vertical', // vertical by default, but can also be "horizontal"
        },
        renderSeparately: {
            type: Boolean,
            default: false, // Render charts separately by default
        },
        stacked: {
            type: Boolean,
            default: false, // Enable or disable stacked bar charts
        },
    },
    data() {
        return {
            isMobile: window.innerWidth < 756,
            chartOptions: null,
            series: [],
            combinedSeries: [],
            secondSeries: [],
            secondChartOptions: null,
            chartHeight: 300,
            chartWidth: '100%',
        };
    },
    computed: {
        chartClass() {
            return {
                Chart: true,
                'is-half-height': this.halfHeight,
                'is-three-forth-height': this.threeForthHeight,
                'is-half-width': this.halfWidth,
                'is-quarter-height': this.quarterHeight,
                'is-inActive': this.inActive,
                'is-sticky': this.isSticky,
                'is-bar-chart': this.chartType === 'bar',
                'is-pie-chart': this.chartType === 'pie',
                'has-title': this.title?.length > 0,
                'is-single-series': !this.renderSeparately,
                'is-mobile': this.isMobile,
                'has-dual-charts':
                    (this.chartType === 'pie' && this.secondValues.length > 0) ||
                    (this.renderSeparately && this.secondValues.length > 0),
            };
        },
        chartType() {
            return this.type;
        },
        maxValue() {
            return Math.max(...this.values.map(v => v.value));
        },
        avgValue() {
            const total = this.values.reduce((acc, v) => acc + v.value, 0);
            return total / new Date().getDate();
        },
        isDualChartView() {
            return (
                (this.chartType === 'pie' && this.secondValues.length > 0) ||
                (this.renderSeparately && this.secondValues.length > 0)
            );
        },
    },
    watch: {
        type: {
            handler() {
                this.updateChart(this.values, this.secondValues);
            },
            immediate: true,
            deep: true,
        },
        values: {
            handler(values) {
                this.updateChart(values, this.secondValues);
            },
            immediate: true,
            deep: true,
        },
        secondValues: {
            handler(values) {
                this.updateChart(this.values, values);
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        updateChart(values, secondValues) {
            if (!values.length && !secondValues.length) return;

            if (this.chartType === 'pie') {
                this.updatePieCharts(values, secondValues);
            } else if (this.chartType === 'bar') {
                if (secondValues && secondValues.length > 0) {
                    this.updateBarChart(values, secondValues);
                } else {
                    this.updateSingleBarChart(values);
                }
            }

            // Update dimensions after configuring the chart
            this.$nextTick(() => {
                this.updateChartDimensions();
            });
        },

        updatePieCharts(values, secondValues) {
            this.chartOptions = this.generatePieChartOptions(values);
            this.series = this.generatePieChartSeries(values);

            if (secondValues.length) {
                this.secondChartOptions = this.generatePieChartOptions(secondValues);
                this.secondSeries = this.generatePieChartSeries(secondValues);
            }
        },

        updateBarChart(firstValues, secondValues) {
            if (!firstValues.length && !secondValues.length) return;

            let firstCategories, secondCategories, firstData, secondData, firstColors, secondColors;

            if (this.groupByService) {
                // Group by service and sort values
                const groupedFirstValues = this.groupByServiceFunc(firstValues);
                const groupedSecondValues = this.groupByServiceFunc(secondValues);

                firstCategories = this.sortAndToArray(groupedFirstValues);
                secondCategories = this.sortAndToArray(groupedSecondValues);

                firstData = firstCategories.map(([key, value]) => value);
                secondData = secondCategories.map(([key, value]) => value);

                firstColors = firstCategories.map(([key]) => this.getColorByKey(key, firstValues));
                secondColors = secondCategories.map(([key]) =>
                    this.getColorByKey(key, secondValues),
                );
            } else {
                // Otherwise, just use the values directly
                firstCategories = firstValues.map(v => v.rawDate);
                secondCategories = secondValues.map(v => v.rawDate);

                firstData = firstValues.map(v => v.value);
                secondData = secondValues.map(v => v.value);
            }

            const totalFirstValues = firstData.reduce((acc, val) => acc + val, 0);
            const totalSecondValues = secondData.reduce((acc, val) => acc + val, 0);

            // Generate chart options
            this.chartOptions = this.generateBarChartOptions(
                firstCategories,
                totalFirstValues,
                totalSecondValues,
                firstColors,
            );

            if (this.renderSeparately) {
                this.series = this.generateBarChartSeries(firstData);
                this.secondChartOptions = this.generateBarChartOptions(
                    secondCategories,
                    totalSecondValues,
                    totalFirstValues,
                    secondColors,
                );
                this.secondSeries = this.generateBarChartSeries(secondData);
            } else {
                this.series = [
                    { name: 'Umsatz', data: firstData },
                    { name: 'Vergleich', data: secondData },
                ];
            }
        },

        updateSingleBarChart(values) {
            const categories = values.map(v => v.rawDate);
            const data = values.map(v => v.value);
            this.series = [
                {
                    name: 'Umsatz',
                    data: data,
                },
            ];

            // Define the chart options
            this.chartOptions = {
                chart: {
                    type: 'bar',
                    offsetX: this.isMobile ? 0 : 5,
                    toolbar: { show: false },
                    fontFamily: 'inherit',
                },
                plotOptions: {
                    bar: {
                        borderRadius: 3,
                        borderRadiusApplication: 'end',
                        horizontal: false,
                        columnWidth: this.getColumnWidth(),
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: false,
                    width: 1,
                    colors: ['transparent'],
                },
                xaxis: {
                    categories,
                    labels: {
                        formatter: (val, index, options) => {
                            const formattedDate = options?.i
                                ? this.values[options.i]?.formattedDate
                                : null;
                            const formattedValue = formattedDate
                                ? formattedDate
                                : format(new Date(val), 'dd.MM');

                            // On mobile, show fewer labels
                            if (this.isMobile) {
                                if (options?.i % 6 === 0) return formattedValue;
                                return '';
                            } else {
                                if (options?.i % 4 === 0) return formattedValue;
                                return '';
                            }
                        },
                        style: {
                            colors: 'rgba(255, 255, 255, 0.75)',
                            fontSize: this.isMobile ? '8px' : '11px',
                        },
                        rotateAlways: this.isMobile,
                        rotate: this.isMobile ? -45 : 0,
                    },
                },
                yaxis: this.isMobile
                    ? {
                          show: false,
                      }
                    : {
                          labels: {
                              offsetX: 12,
                              formatter: (val, index) => {
                                  if (index % 2 === 1 && this.isMobile && val >= 1_000_00) {
                                      const formattedPrice = (val / 1_000_00).toFixed(1);
                                      return `${formattedPrice}K €`;
                                  }
                                  return index % 2 === 1 ? priceToEuroString(val) : '';
                              },
                              style: {
                                  colors: 'rgba(255, 255, 255, 0.75)',
                                  fontSize: this.isMobile ? '10px' : '11px',
                              },
                          },
                          title: {
                              text: '',
                              style: {
                                  color: 'rgba(255, 255, 255, 0.5)',
                              },
                          },
                      },
                fill: {
                    opacity: 1,
                    colors: ['#09d490'],
                },
                grid: {
                    borderColor: this.isMobile
                        ? 'rgba(255, 255, 255, 0)'
                        : 'rgba(255, 255, 255, 0.2)',
                    padding: {
                        left: this.isMobile ? 0 : 10,
                        right: this.isMobile ? 0 : 10,
                    },
                },
                tooltip: {
                    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                        const value = series[seriesIndex][dataPointIndex];
                        const date = w.globals.labels[dataPointIndex];
                        // Get the formatted date from the original values if available
                        const formattedDate =
                            this.values[dataPointIndex]?.formattedDate ||
                            format(new Date(date), 'dd.MM.yyyy');

                        return `
                        <div class="tooltip-content">
                            <span><b>${priceToEuroString(value)}</b></span>
                            <br>
                            <span>${formattedDate}</span>
                        </div>`;
                    },
                },
                annotations: {
                    yaxis: this.isMobile
                        ? []
                        : [
                              {
                                  y: this.maxValue,
                                  label: {
                                      style: {
                                          color: '#000',
                                          background: '#09d490',
                                      },
                                      text: priceToEuroString(this.maxValue),
                                  },
                              },
                              {
                                  y: this.avgValue,
                                  label: {
                                      style: {
                                          color: '#000',
                                          background: '#f7d01c',
                                      },
                                      text: priceToEuroString(this.avgValue),
                                  },
                              },
                          ],
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                height: '100%',
                            },
                            legend: {
                                position: 'bottom',
                                fontSize: '10px',
                            },
                        },
                    },
                ],
            };
        },

        getColumnWidth() {
            if (this.isMobile) {
                return window.innerWidth < 375 ? '3px' : '5px';
            } else {
                return window.innerWidth < 1000 ? '10px' : '20px';
            }
        },

        sortAndToArray(groupedValues) {
            return Object.entries(groupedValues).sort(([, valueA], [, valueB]) => valueB - valueA);
        },

        generatePieChartOptions(values) {
            const groupedByService = this.groupByService
                ? values.reduce((acc, v) => {
                      if (!v.service) return acc;
                      if (!acc[v.service]) acc[v.service] = 0;
                      acc[v.service] += v.value;
                      return acc;
                  }, {})
                : null;

            const sortedEntries = Object.entries(groupedByService)
                .sort(([, valueA], [, valueB]) => valueB - valueA) // Sort descending
                .map(([key, value]) => ({ key, value }));

            const labels = sortedEntries.map(entry => entry.key);
            const series = sortedEntries.map(entry => entry.value);

            const colors = sortedEntries.map(
                entry => values.find(v => v.service === entry.key)?.color || '#09d490',
            );

            return {
                chart: {
                    type: 'donut',
                    toolbar: { show: false },
                    fontFamily: 'inherit',
                },
                labels,
                dataLabels: {
                    enabled: !this.isMobile || (this.isMobile && !this.isDualChartView),
                    formatter: (val, opts) => `${val.toFixed(1)}%`,
                    style: {
                        fontSize: this.isMobile ? '10px' : '14px',
                        color: '#000',
                        textShadow: 'rgb(0, 0, 0)',
                    },
                    dropShadow: {
                        top: 0,
                        left: 0,
                        blur: 10,
                        color: 'rgba(0,0,0,0.2)',
                        opacity: 1,
                    },
                },
                colors,
                plotOptions: {
                    pie: {
                        customScale: 1,
                        dataLabels: {
                            offset: this.isMobile ? -10 : -20,
                            minAngleToShowLabel: this.isMobile ? 30 : 25,
                        },
                        donut: {
                            size: this.isMobile ? '85%' : '65%', // Larger donut hole on mobile
                        },
                    },
                },
                tooltip: {
                    custom: ({ series, seriesIndex, w }) => {
                        const value = series[seriesIndex];
                        const category = w.globals.labels[seriesIndex];
                        return `
                    <div class="tooltip-content">
                        <span><b>${category}</b></span>
                        <br>
                        <span>${priceToEuroString(value)}</span>
                    </div>`;
                    },
                },
                legend: {
                    show: !this.isMobile || (this.isMobile && !this.isDualChartView),
                    position: this.isMobile ? 'bottom' : 'bottom',
                    fontSize: this.isMobile ? '8px' : '11px',
                    labels: { colors: 'rgba(255, 255, 255, 0.7)' },
                    itemMargin: {
                        horizontal: this.isMobile ? 5 : 10,
                        vertical: this.isMobile ? 0 : 5,
                    },
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                height: '100%',
                            },
                            legend: {
                                position: 'bottom',
                                fontSize: '8px',
                            },
                        },
                    },
                ],
            };
        },

        generatePieChartSeries(values) {
            const groupedByService = this.groupByService
                ? values.reduce((acc, v) => {
                      if (!v.service) return acc;
                      if (!acc[v.service]) acc[v.service] = 0;
                      acc[v.service] += v.value;
                      return acc;
                  }, {})
                : null;

            const sortedValues = Object.values(groupedByService).sort((a, b) => b - a); // Sorted descending amounts in the groups

            return sortedValues;
        },

        generateBarChartOptions(categories, totalFirstValues, totalSecondValues, colors) {
            return {
                chart: {
                    type: 'bar',
                    toolbar: { show: false },
                    stacked: this.stacked,
                    fontFamily: 'inherit',
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            position: 'top',
                        },
                        horizontal: this.direction === 'horizontal',
                        borderRadius: 3,
                        borderRadiusApplication: 'end',
                        distributed: !this.stacked,
                        columnWidth: this.getColumnWidth(),
                    },
                },
                dataLabels: {
                    enabled: !this.isMobile,
                    formatter: (val, opts) => {
                        if (this.isMobile) return '';
                        const percentage = (
                            (val /
                                (opts.seriesIndex === 0 ? totalFirstValues : totalSecondValues)) *
                            100
                        ).toFixed(1);
                        return `${priceToEuroString(val)}`;
                    },
                    offsetY: 6,
                    offsetX: this.isMobile ? 0 : 25,
                    dropShadow: {
                        top: 1,
                        left: 1,
                        blur: 0,
                        color: '#000',
                        opacity: 1,
                    },
                    style: {
                        fontSize: this.isMobile ? '8px' : '10px',
                        colors: ['#fff'],
                    },
                },
                xaxis: {
                    categories: categories,
                    labels: {
                        style: {
                            colors: 'rgba(255, 255, 255, 0.7)',
                            fontSize: this.isMobile ? '8px' : '11px',
                        },
                        formatter: function(val, i) {
                            if (i % 2 == 0) return priceToEuroString(val);
                        },
                        rotate: this.isMobile ? -45 : 0,
                        rotateAlways: this.isMobile,
                    },
                },
                yaxis: {
                    labels: {
                        offsetX: this.isMobile ? 5 : 40,
                        show: !this.isMobile,
                        style: {
                            colors: 'rgba(255, 255, 255, 0.7)',
                            fontSize: this.isMobile ? '8px' : '11px',
                        },
                        formatter: function(val) {
                            if (typeof val === 'object' && val.length > 0) {
                                return val[0]; // Take first element if it's an array
                            }
                            return val;
                        },
                    },
                },
                fill: { opacity: 1, colors: colors },
                grid: {
                    borderColor: 'rgba(255, 255, 255, 0.1)',
                    padding: {
                        left: this.isMobile ? 0 : 10,
                        right: this.isMobile ? 0 : 10,
                    },
                },
                tooltip: {
                    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                        const value = series[seriesIndex][dataPointIndex];
                        const categoryOrDate = w.globals.labels[dataPointIndex];
                        const percentage = (
                            (value / (seriesIndex === 0 ? totalFirstValues : totalSecondValues)) *
                            100
                        ).toFixed(1);
                        return `
                        <div class="tooltip-content">
                            <span><b>${priceToEuroString(value)}</b> (${percentage}%)</span><br>
                            <span>${
                                Array.isArray(categoryOrDate) ? categoryOrDate[0] : categoryOrDate
                            }</span>
                        </div>`;
                    },
                },
                legend: {
                    show: this.stacked,
                    position: this.isMobile ? 'bottom' : 'right',
                    fontSize: this.isMobile ? '8px' : '11px',
                },
                responsive: [
                    {
                        breakpoint: 480,
                        options: {
                            chart: {
                                height: '100%',
                            },
                            legend: {
                                position: 'bottom',
                                fontSize: '8px',
                            },
                        },
                    },
                ],
            };
        },

        generateBarChartSeries(firstData, secondData) {
            const series = [{ name: 'Umsatz', data: firstData }];
            if (secondData) series.push({ name: 'Vergleich', data: secondData });
            return series;
        },

        getColorByKey(key, values) {
            const matchingEntry = values.find(v => v.service === key);
            return matchingEntry ? matchingEntry.color : '#09d490';
        },

        groupByServiceFunc(values) {
            if (!values) return {};

            return values.reduce((grouped, value) => {
                const service = value.service || 'No Service';
                if (!grouped[service]) {
                    grouped[service] = 0;
                }
                grouped[service] += value.value;
                return grouped;
            }, {});
        },

        getColorMappings(firstValues, secondValues) {
            const colorMap = {};

            const allValues = [...firstValues, ...secondValues];
            allValues.forEach(val => {
                if (val.service && val.color) {
                    colorMap[val.service] = val.color;
                }
            });

            return colorMap;
        },

        updateChartHeight() {
            const hasTitle = this.title?.length > 0;
            let baseHeight = this.$refs.Chart.clientHeight - (hasTitle ? 0 : 0);

            // Mobile-specific height adjustments
            if (this.isMobile) {
                if (this.isDualChartView) {
                    // For dual charts on mobile, make them smaller
                    baseHeight = Math.min(baseHeight, 220);
                } else {
                    // For single chart on mobile
                    baseHeight = Math.min(baseHeight, 280);
                }
            } else {
                // Handle dual charts on desktop
                if (this.isDualChartView) {
                    baseHeight = Math.min(baseHeight, this.$refs.Chart.clientHeight - 70);
                }
            }

            // Apply component's height modifiers
            if (this.halfHeight) baseHeight = Math.max(baseHeight * 0.7, 180);
            if (this.quarterHeight) baseHeight = Math.max(baseHeight * 0.4, 120);
            if (this.threeForthHeight) baseHeight = Math.max(baseHeight * 0.85, 220);

            this.chartHeight = baseHeight;
        },

        updateChartWidth() {
            this.isMobile = window.innerWidth < 756;
            const chartPadding = this.isMobile ? 10 : 20;

            if (this.isDualChartView) {
                // For dual charts, calculate width based on container and available space
                if (this.isMobile) {
                    // On mobile, make charts full width but stacked vertically in CSS
                    this.chartWidth = this.$refs.Chart.clientWidth - chartPadding;
                } else {
                    // On desktop, make each chart take up half the width
                    this.chartWidth = this.$refs.Chart.clientWidth / 2 - chartPadding;
                }
            } else {
                // For single chart, use full available width
                this.chartWidth = this.$refs.Chart.clientWidth - chartPadding;
            }
        },

        updateChartDimensions() {
            this.updateChartHeight();
            this.updateChartWidth();
        },

        handleResize() {
            this.isMobile = window.innerWidth < 756;
            this.$nextTick(() => {
                this.updateChartDimensions();
                // On resize, we might need to redraw the chart with new options
                this.updateChart(this.values, this.secondValues);
            });
        },
    },

    mounted() {
        this.$nextTick(() => {
            this.updateChart(this.values, this.secondValues);
            this.updateChartDimensions();
        });

        window.addEventListener('resize', this.handleResize);
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.handleResize);
    },
};
</script>

<style lang="scss">
.Chart {
    width: 100%;
    background-image: linear-gradient(-42deg, color(blue-light) 0%, color(blue-dark) 100%);
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.15);
    height: 380px;
    margin-bottom: 20px;
    position: relative;
    transition: all 0.3s ease;
    overflow: hidden;
    padding: 0 0px;
    border-radius: 5px;

    &.is-sticky {
        margin-bottom: 16px;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    &.is-inActive {
        filter: saturate(0) opacity(0.35);
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0);
    }

    &.is-half-width {
        height: 380px;
        width: 50%;
        margin-right: 30px;
        display: inline-block;
        transform: translateX(0);

        &:last-of-type {
            margin-right: 0;
        }
    }

    &.is-half-height {
        height: 240px;

        &.has-title {
            height: 290px;
        }
    }

    &.is-three-forth-height {
        height: 320px;
    }

    &.is-quarter-height {
        height: 140px;

        h2 {
            display: none;
        }
    }

    h2 {
        font-size: 1.5rem;
        color: #ffffff;
        padding-top: 15px;
        padding-left: 15px;

        @media (max-width: breakpoint(tabletLandscape)) {
            padding-top: 15px;
            padding-left: 20px;
            font-size: 1.25rem;
        }
    }

    &.is-pie-chart {
        * path {
            stroke: rgba(#000, 0.2) !important;
        }
    }

    &:not(.is-single-series) {
        * text {
            font-family: inherit !important;
            text-shadow: 1px 1px black !important;
        }
    }

    &.is-mobile {
        .ChartsWrap {
            flex-direction: column;

            .ChartContainer {
                width: 100%;
                margin-bottom: 20px;
                height: auto;
            }
        }

        h2 {
            font-size: 1.2rem;
            padding-top: 10px;
            padding-left: 10px;
        }
    }

    .apexcharts-yaxis-label,
    .apexcharts-xaxis-label {
        fill: rgba(255, 255, 255, 0.9) !important; /* Increased opacity for better contrast */
        font-weight: 500 !important;
        text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5) !important;
    }

    /* Small rectangular background for labels to improve readability */
    .apexcharts-yaxis-label tspan,
    .apexcharts-xaxis-label tspan {
        background: rgba(0, 0, 0, 0.3);
        padding: 2px 4px;
        border-radius: 2px;
    }

    /* Improve readability on dark backgrounds */
    .apexcharts-yaxis-texts-g text,
    .apexcharts-xaxis-texts-g text {
        filter: drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.8));
    }
}

.ChartsWrap {
    display: flex;

    @media (max-width: 756px) {
        flex-direction: column;
    }
}

.ChartContainer {
    flex: 1;
    height: 320px;
    margin: 0 5px;

    @media (max-width: 756px) {
        width: 100%;
        margin: 0 0 20px 0;
        height: auto;
    }
}
.tooltip-content {
    background: #fff;
    color: #000;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    font-size: 12px;

    @media (max-width: 756px) {
        padding: 6px;
        font-size: 10px;
    }

    b {
        font-weight: bold;
    }
}

/* Mobile responsive adjustments for charts */
@media (max-width: 756px) {
    .Chart {
        height: auto;
        min-height: 250px;

        &.has-dual-charts {
            min-height: 480px;
        }

        &.is-half-height {
            min-height: 200px;

            &.has-dual-charts {
                min-height: 400px;
            }
        }

        &.is-quarter-height {
            min-height: 120px;

            &.has-dual-charts {
                min-height: 240px;
            }
        }
    }

    .apexcharts-legend {
        padding: 0 !important;
        margin: 0 !important;
    }

    .apexcharts-tooltip {
        max-width: 200px;
    }
}

/* Special handling for pie charts on small screens */
@media (max-width: 480px) {
    .is-pie-chart .apexcharts-legend-series {
        margin: 2px 5px !important;
    }

    .apexcharts-legend-marker {
        margin-right: 3px !important;
    }
}
</style>
