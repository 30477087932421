var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "analytics-table"
  }, [_vm.sortedDrivers.length > 0 ? _c('table', [_c('thead', [_c('tr', [_c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'name'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('name', 'string');
      }
    }
  }, [_c('span', {
    staticClass: "header-content"
  }, [_c('span', {
    staticClass: "header-text"
  }, [_vm._v(_vm._s(_vm.selectedCars ? 'Fahrzeug' : 'Fahrer'))]), _c('i', {
    staticClass: "sort-icon ri-arrow-up-down-line"
  })])]), (_vm.selectedCars ? _vm.sortedCars[0].performanceScore : _vm.sortedDrivers[0].performanceScore) ? _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'performanceScore'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('performanceScore');
      }
    }
  }, [_vm._m(0)]) : _vm._e(), _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'finalTotalAmount'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('finalTotalAmount');
      }
    }
  }, [_vm._m(1)]), _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'averageRevenuePerKm'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('averageRevenuePerKm');
      }
    }
  }, [_vm._m(2)]), _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'emptyPercentage'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('emptyPercentage');
      }
    }
  }, [_vm._m(3)]), _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'totalDistance'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('totalDistance');
      }
    }
  }, [_vm._m(4)]), _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'hiredDistance'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('hiredDistance');
      }
    }
  }, [_vm._m(5)]), _c('th', {
    class: [{
      isSorted: _vm.currentSortColumn === 'dailyRevenues'
    }, {
      asc: _vm.currentSortOrder === 'asc'
    }, {
      desc: _vm.currentSortOrder === 'desc'
    }],
    on: {
      "click": function click($event) {
        return _vm.sortTable('dailyRevenues');
      }
    }
  }, [_vm._m(6)]), _vm._l(_vm.daysInMonth, function (day) {
    return _c('th', {
      key: day.getTime(),
      class: [{
        isSorted: _vm.currentSortColumn === day
      }, {
        asc: _vm.currentSortOrder === 'asc'
      }, {
        desc: _vm.currentSortOrder === 'desc'
      }],
      on: {
        "click": function click($event) {
          return _vm.sortTable(day);
        }
      }
    }, [_c('span', {
      staticClass: "header-content"
    }, [_c('span', {
      staticClass: "header-text"
    }, [_vm._v(_vm._s(_vm.format(day, 'dd.MM')))]), _c('i', {
      staticClass: "sort-icon ri-arrow-up-down-line"
    })])]);
  })], 2)]), _c('tbody', _vm._l(_vm.selectedCars ? _vm.sortedCars : _vm.sortedDrivers, function (driver) {
    return _c('tr', {
      key: _vm.selectedCars ? driver.licenseNumber : driver.name
    }, [_c('td', {
      staticClass: "name-cell"
    }, [_c('b', [_vm._v(" " + _vm._s(_vm.selectedCars ? driver.licenseNumber : driver.name) + " ")])]), (_vm.selectedCars ? _vm.sortedCars[0].performanceScore : _vm.sortedDrivers[0].performanceScore) ? _c('td', {
      class: ['metric-cell', _vm.performanceClass(driver.performanceScore)]
    }, [_vm._v(" " + _vm._s((driver.performanceScore * 100 || 0).toFixed(2)) + "% ")]) : _vm._e(), _c('td', {
      staticClass: "metric-cell"
    }, [_vm._v(_vm._s(_vm.formatCurrency(driver.finalTotalAmount)))]), _c('td', {
      class: ['metric-cell', _vm.averageRevenuePerKmClass(driver.averageRevenuePerKm)]
    }, [_vm._v(" " + _vm._s(_vm.formatAverage(driver.averageRevenuePerKm)) + " ")]), _c('td', {
      class: ['metric-cell', _vm.emptyPercentageClass(driver.emptyPercentage)]
    }, [_vm._v(" " + _vm._s(_vm.formatPercentage(driver.emptyPercentage * 100)) + " ")]), _c('td', {
      staticClass: "metric-cell"
    }, [_vm._v(_vm._s(_vm.formatDistance(driver.totalDistance)))]), _c('td', {
      staticClass: "metric-cell"
    }, [_vm._v(_vm._s(_vm.formatDistance(driver.hiredDistance)))]), _c('td', {
      staticClass: "metric-cell"
    }, [_vm._v(_vm._s(_vm.formatDistance(driver.forHireDistance)))]), _vm._l(_vm.daysInMonth, function (day) {
      return _c('td', {
        key: day.getTime(),
        class: ['day-cell', _vm.revenueClass(driver.dailyRevenues.find(function (d) {
          return d.day.getTime() === day.getTime();
        }).amount)]
      }, [_vm._v(" " + _vm._s(_vm.formatCurrency(driver.dailyRevenues.find(function (d) {
        return d.day.getTime() === day.getTime();
      }).amount)) + " ")]);
    })], 2);
  }), 0)]) : _c('EmptyState')], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "header-content"
  }, [_c('span', {
    staticClass: "header-text"
  }, [_vm._v("Performance")]), _c('i', {
    staticClass: "sort-icon ri-arrow-up-down-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "header-content"
  }, [_c('span', {
    staticClass: "header-text"
  }, [_vm._v("Umsatz")]), _c('i', {
    staticClass: "sort-icon ri-arrow-up-down-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "header-content"
  }, [_c('span', {
    staticClass: "header-text"
  }, [_vm._v("KM Schnitt")]), _c('i', {
    staticClass: "sort-icon ri-arrow-up-down-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "header-content"
  }, [_c('span', {
    staticClass: "header-text"
  }, [_vm._v("Leerquote")]), _c('i', {
    staticClass: "sort-icon ri-arrow-up-down-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "header-content"
  }, [_c('span', {
    staticClass: "header-text"
  }, [_vm._v("Gesamt Km")]), _c('i', {
    staticClass: "sort-icon ri-arrow-up-down-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "header-content"
  }, [_c('span', {
    staticClass: "header-text"
  }, [_vm._v("Besetzt Km")]), _c('i', {
    staticClass: "sort-icon ri-arrow-up-down-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "header-content"
  }, [_c('span', {
    staticClass: "header-text"
  }, [_vm._v("Leer Km")]), _c('i', {
    staticClass: "sort-icon ri-arrow-up-down-line"
  })]);
}]

export { render, staticRenderFns }