var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "performance-chart"
  }, [_c('div', {
    staticClass: "chart-header"
  }, [_c('h3', [_vm._v("Performance-Vergleich")]), _c('div', {
    staticClass: "metric-selector"
  }, _vm._l(_vm.metrics, function (metric) {
    return _c('Button', {
      key: metric.id,
      class: ['metric-button', _vm.selectedMetric === metric.id ? 'active' : ''],
      attrs: {
        "size": "small"
      },
      on: {
        "onClick": function onClick() {
          return _vm.handleMetricChange(metric.id);
        }
      }
    }, [_vm._v(" " + _vm._s(metric.label) + " ")]);
  }), 1)]), _c('div', {
    ref: "chartContainer",
    staticClass: "chart-container"
  }, [_vm.loading ? _c('div', {
    staticClass: "chart-loader"
  }, [_c('i', {
    staticClass: "ri-loader-4-line spin"
  }), _c('span', [_vm._v("Lade Daten...")])]) : _vm._e()]), _c('div', {
    staticClass: "chart-legend"
  }, _vm._l(_vm.topPerformers, function (item, index) {
    return _c('div', {
      key: index,
      staticClass: "legend-item"
    }, [_c('div', {
      staticClass: "performer-rank"
    }, [_vm._v(_vm._s(index + 1))]), _c('div', {
      staticClass: "legend-marker",
      style: {
        backgroundColor: _vm.colorScale(index)
      }
    }), _c('div', {
      staticClass: "legend-text"
    }, [_c('div', {
      staticClass: "name"
    }, [_vm._v(_vm._s(item.name))]), _c('div', {
      staticClass: "value"
    }, [_vm._v(_vm._s(_vm.formatValue(item[_vm.selectedMetric])))])]), _c('div', {
      staticClass: "trend-indicator",
      class: _vm.getTrendClass(item.trend)
    }, [_c('i', {
      class: _vm.getTrendIcon(item.trend)
    }), _c('span', [_vm._v(_vm._s(_vm.getTrendText(item.trend)))])])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }