<template>
    <div class="analytics-table">
        <table v-if="sortedDrivers.length > 0">
            <thead>
                <tr>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'name' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('name', 'string')"
                    >
                        <span class="header-content">
                            <span class="header-text">{{
                                selectedCars ? 'Fahrzeug' : 'Fahrer'
                            }}</span>
                            <i class="sort-icon ri-arrow-up-down-line"></i>
                        </span>
                    </th>
                    <th
                        v-if="
                            selectedCars
                                ? sortedCars[0].performanceScore
                                : sortedDrivers[0].performanceScore
                        "
                        :class="[
                            { isSorted: currentSortColumn === 'performanceScore' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('performanceScore')"
                    >
                        <span class="header-content">
                            <span class="header-text">Performance</span>
                            <i class="sort-icon ri-arrow-up-down-line"></i>
                        </span>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'finalTotalAmount' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('finalTotalAmount')"
                    >
                        <span class="header-content">
                            <span class="header-text">Umsatz</span>
                            <i class="sort-icon ri-arrow-up-down-line"></i>
                        </span>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'averageRevenuePerKm' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('averageRevenuePerKm')"
                    >
                        <span class="header-content">
                            <span class="header-text">KM Schnitt</span>
                            <i class="sort-icon ri-arrow-up-down-line"></i>
                        </span>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'emptyPercentage' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('emptyPercentage')"
                    >
                        <span class="header-content">
                            <span class="header-text">Leerquote</span>
                            <i class="sort-icon ri-arrow-up-down-line"></i>
                        </span>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'totalDistance' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('totalDistance')"
                    >
                        <span class="header-content">
                            <span class="header-text">Gesamt Km</span>
                            <i class="sort-icon ri-arrow-up-down-line"></i>
                        </span>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'hiredDistance' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('hiredDistance')"
                    >
                        <span class="header-content">
                            <span class="header-text">Besetzt Km</span>
                            <i class="sort-icon ri-arrow-up-down-line"></i>
                        </span>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === 'dailyRevenues' },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        @click="sortTable('dailyRevenues')"
                    >
                        <span class="header-content">
                            <span class="header-text">Leer Km</span>
                            <i class="sort-icon ri-arrow-up-down-line"></i>
                        </span>
                    </th>
                    <th
                        :class="[
                            { isSorted: currentSortColumn === day },
                            { asc: currentSortOrder === 'asc' },
                            { desc: currentSortOrder === 'desc' },
                        ]"
                        v-for="day in daysInMonth"
                        @click="sortTable(day)"
                        :key="day.getTime()"
                    >
                        <span class="header-content">
                            <span class="header-text">{{ format(day, 'dd.MM') }}</span>
                            <i class="sort-icon ri-arrow-up-down-line"></i>
                        </span>
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="driver in selectedCars ? sortedCars : sortedDrivers"
                    :key="selectedCars ? driver.licenseNumber : driver.name"
                >
                    <td class="name-cell">
                        <b>
                            {{ selectedCars ? driver.licenseNumber : driver.name }}
                        </b>
                    </td>
                    <td
                        :class="['metric-cell', performanceClass(driver.performanceScore)]"
                        v-if="
                            selectedCars
                                ? sortedCars[0].performanceScore
                                : sortedDrivers[0].performanceScore
                        "
                    >
                        {{ (driver.performanceScore * 100 || 0).toFixed(2) }}%
                    </td>
                    <td class="metric-cell">{{ formatCurrency(driver.finalTotalAmount) }}</td>
                    <td
                        :class="[
                            'metric-cell',
                            averageRevenuePerKmClass(driver.averageRevenuePerKm),
                        ]"
                    >
                        {{ formatAverage(driver.averageRevenuePerKm) }}
                    </td>
                    <td :class="['metric-cell', emptyPercentageClass(driver.emptyPercentage)]">
                        {{ formatPercentage(driver.emptyPercentage * 100) }}
                    </td>
                    <td class="metric-cell">{{ formatDistance(driver.totalDistance) }}</td>
                    <td class="metric-cell">{{ formatDistance(driver.hiredDistance) }}</td>
                    <td class="metric-cell">{{ formatDistance(driver.forHireDistance) }}</td>
                    <td
                        v-for="day in daysInMonth"
                        :key="day.getTime()"
                        :class="[
                            'day-cell',
                            revenueClass(
                                driver.dailyRevenues.find(d => d.day.getTime() === day.getTime())
                                    .amount,
                            ),
                        ]"
                    >
                        {{
                            formatCurrency(
                                driver.dailyRevenues.find(d => d.day.getTime() === day.getTime())
                                    .amount,
                            )
                        }}
                    </td>
                </tr>
            </tbody>
        </table>
        <EmptyState v-else />
    </div>
</template>

<script>
import { fakerDE } from '@faker-js/faker';
import { fakerTR } from '@faker-js/faker';
import { fakerAR } from '@faker-js/faker';
import { addDays, endOfMonth, format, startOfMonth } from 'date-fns';
import { round2d } from '@/lib/helper';
import EmptyState from '@/components/EmptyState.vue';

export default {
    name: 'AnalyticsTable',
    components: {
        EmptyState,
    },

    props: {
        startAt: Date,
        endAt: Date,
        shifts: Array,
        option: Object,

        useFakeData: {
            type: Boolean,
            default: false,
        },
    },
    data: () => {
        return {
            format,
            round2d,
            currentSortColumn: 'performanceScore',
            currentSortOrder: 'desc',
            dailyRevenue: {},
        };
    },
    watch: {
        option: {
            handler(value) {
                if (value.id === 'car') {
                    this.currentSortColumn = 'performanceScore';
                } else {
                    this.currentSortColumn = 'performanceScore';
                }
            },
            immediate: true,
            deep: true,
        },
        sortedDrivers: {
            handler() {
                setTimeout(() => {
                    const cvs = this.createCSV({ option: 'driver' });
                    this.$emit('onCVSChange', cvs);
                }, 100);
            },
            immediate: true,
            deep: true,
        },
        sortedCars: {
            handler() {
                setTimeout(() => {
                    const cvs = this.createCSV({ option: 'car' });
                    this.$emit('onCVSChange', cvs);
                }, 100);
            },
            immediate: true,
            deep: true,
        },
    },
    computed: {
        selectedCars() {
            return this.option.id === 'car';
        },
        sortedCars() {
            const processedCars = [...this.carsWithPerformance];

            if (this.useFakeData) {
                processedCars.forEach(car => {
                    car.licenseNumber = 'B-' + fakerDE.string.alphanumeric(6).toUpperCase();
                });
            }

            return processedCars.sort((a, b) => this.compareValues(a, b, this.currentSortColumn));
        },
        sortedDrivers() {
            const processedDrivers = [...this.driverWithPerformance];

            if (this.useFakeData) {
                processedDrivers.forEach(driver => {
                    driver.name = this.handleRandomName();

                    driver.licenseNumber = 'B-' + fakerDE.string.alphanumeric(6).toUpperCase();
                });
            }

            return processedDrivers.sort((a, b) =>
                this.compareValues(a, b, this.currentSortColumn),
            );
        },
        cars() {
            const cars = Object.groupBy(this.shifts, shift => shift.licenseNumber);
            const processedCars = this.processShifts(cars);
            return Object.values(processedCars);
        },
        drivers() {
            const driver = Object.groupBy(this.shifts, ({ driver }) => driver.driverNumber);
            const processedDriver = this.processShifts(driver);
            return Object.values(processedDriver);
        },
        carsWithPerformance() {
            return this.cars.map(car => ({
                ...car,
                performanceScore: this.calculatePerformanceScoreCar(car),
            }));
        },
        driverWithPerformance() {
            return this.drivers.map(driver => ({
                ...driver,
                performanceScore: this.calculatePerformanceScoreDriver(driver),
            }));
        },
        daysInMonth() {
            const today = new Date(this.startAt);
            const start = startOfMonth(today);
            const end = endOfMonth(today);
            const days = [];
            for (let day = start; day <= end; day = addDays(day, 1)) {
                days.push(day);
            }
            // only show days that are in the past or today
            return days.filter(day => day <= new Date());
        },
        shiftsGroupedByDriver() {
            return Object.groupBy(this.shifts, ({ driver }) => driver.driverNumber);
        },
        shiftsGroupedByCar() {
            return Object.groupBy(this.shifts, shift => shift.licenseNumber);
        },
    },
    methods: {
        handleRandomName() {
            const names = {
                0: `${fakerDE.person.firstName()} ${fakerDE.person.lastName()}`,
                1: `${fakerTR.person.firstName()} ${fakerTR.person.lastName()}`,
            };
            return names[Math.floor(Math.random() * 2)];
        },
        processShifts(givenDriver) {
            const driver = { ...givenDriver };
            for (const driverNumber in driver) {
                const driverShifts = driver[driverNumber];
                const finalTotalAmount = driverShifts.reduce(
                    (sum, shift) => sum + shift.finalTotalAmount,
                    0,
                );
                const forHireDistance = driverShifts.reduce(
                    (sum, shift) => sum + shift.forHireDistance,
                    0,
                );
                const hiredDistance = driverShifts.reduce(
                    (sum, shift) => sum + shift.hiredDistance,
                    0,
                );
                const totalDistance = driverShifts.reduce(
                    (sum, shift) => sum + shift.totalDistance,
                    0,
                );
                const emptyPercentage = (totalDistance - hiredDistance) / totalDistance || 0;
                const averageRevenuePerKm = finalTotalAmount / (totalDistance || 1);
                const dailyRevenues = this.daysInMonth.map(day => ({
                    day,
                    amount: this.getDailyRevenue({ driverNumber }, day),
                }));
                driver[driverNumber] = {
                    ...driver[driverNumber][0].driver,
                    ...driver[driverNumber][0],
                    finalTotalAmount: finalTotalAmount,
                    forHireDistance: forHireDistance,
                    totalDistance: totalDistance,
                    emptyPercentage: emptyPercentage,
                    averageRevenuePerKm: averageRevenuePerKm,
                    hiredDistance: hiredDistance,
                    dailyRevenues,
                    hasShifts: driverShifts.length > 0,
                };
            }
            return driver;
        },
        createCSV({ option }) {
            let csvContent = '';
            // Header
            csvContent +=
                'Fahrer,Performance,Umsatz,KM Schnitt,Leerquote,Gesamt Km,Besetzt Km,Leer Km,';
            this.daysInMonth.forEach(day => {
                csvContent += `${this.format(day, 'dd.MM')},`;
            });
            csvContent += '\r\n';
            const drivers = option === 'car' ? this.sortedCars : this.sortedDrivers;
            // Rows
            drivers.forEach(driver => {
                const formattedTotalAmount = this.formatCurrency(
                    driver.finalTotalAmount / 100,
                ).replace(',', '.');
                const formattedPerformanceScore = (driver.performanceScore || 0).toFixed(2) || 0;
                const formattedAverageRevenuePerKm = this.formatAverage(
                    driver.averageRevenuePerKm,
                ).replace(',', '.');
                const formattedEmptyPercentage = this.formatPercentage(
                    driver.emptyPercentage,
                ).replace(',', '.');
                const formattedTotalDistance = this.formatDistance(driver.totalDistance).replace(
                    ',',
                    '.',
                );
                const formattedHiredDistance = this.formatDistance(driver.hiredDistance).replace(
                    ',',
                    '.',
                );
                const formattedForHireDistance = this.formatDistance(
                    driver.forHireDistance,
                ).replace(',', '.');
                csvContent += `${driver.name},${formattedPerformanceScore},${formattedTotalAmount},${formattedAverageRevenuePerKm},${formattedEmptyPercentage},${formattedTotalDistance},${formattedHiredDistance},${formattedForHireDistance},`;
                this.daysInMonth.forEach(day => {
                    const dailyRevenue = this.getDailyRevenue(driver, day);
                    const formattedDailyRevenue = this.formatCurrency(dailyRevenue / 100).replace(
                        ',',
                        '.',
                    );
                    csvContent += `${formattedDailyRevenue},`;
                });
                csvContent += '\r\n';
            });
            return csvContent;
        },
        normalizeValue(value, min, max) {
            return (value - min) / (max - min);
        },
        getMetricMinMax(metric, option) {
            const values =
                option === 'car'
                    ? this.cars.map(car => car[metric])
                    : this.drivers.map(driver => driver[metric]);
            return {
                min: Math.min(...values),
                max: Math.max(...values),
            };
        },
        calculatePerformanceScoreDriver(driver) {
            const weights = {
                finalTotalAmount: 0.4,
                averageRevenuePerKm: 0.2,
                emptyPercentage: 0.1,
                totalDistance: 0.1,
                hiredDistance: 0.1,
                forHireDistance: 0.1,
            };
            const metrics = [
                'finalTotalAmount',
                'averageRevenuePerKm',
                'emptyPercentage',
                'totalDistance',
                'hiredDistance',
                'forHireDistance',
            ];
            let score = 0;
            metrics.forEach(metric => {
                const { min, max } = this.getMetricMinMax(metric);
                let normalizedValue = this.normalizeValue(Number(driver[metric]), min, max);
                // For 'emptyPercentage' and 'forHireDistance', higher values are worse, so we invert the normalization
                if (metric === 'emptyPercentage' || metric === 'forHireDistance') {
                    normalizedValue = 1 - normalizedValue;
                }
                score += normalizedValue * weights[metric];
            });
            return score;
        },
        calculatePerformanceScoreCar(car) {
            const weights = {
                finalTotalAmount: 0.4,
                averageRevenuePerKm: 0.2,
                emptyPercentage: 0.1,
                totalDistance: 0.1,
                hiredDistance: 0.1,
                forHireDistance: 0.1,
            };
            const metrics = [
                'finalTotalAmount',
                'averageRevenuePerKm',
                'emptyPercentage',
                'totalDistance',
                'hiredDistance',
                'forHireDistance',
            ];
            let score = 0;
            metrics.forEach(metric => {
                const { min, max } = this.getMetricMinMax(metric, 'car');
                let normalizedValue = this.normalizeValue(Number(car[metric]), min, max);
                // For 'emptyPercentage' and 'forHireDistance', higher values are worse, so we invert the normalization
                if (metric === 'emptyPercentage' || metric === 'forHireDistance') {
                    normalizedValue = 1 - normalizedValue;
                }
                score += normalizedValue * weights[metric];
            });
            return score;
        },
        sortTable(column) {
            if (this.currentSortColumn === column) {
                this.currentSortOrder = this.currentSortOrder === 'asc' ? 'desc' : 'asc';
            } else {
                this.currentSortColumn = column;
                this.currentSortOrder = 'desc';
            }
        },
        compareValues(a, b, column) {
            let valueA = String(a[column]).replace(',', '.');
            let valueB = String(b[column]).replace(',', '.');
            if (column instanceof Date) {
                valueA = this.getDailyRevenue(a, column);
                valueB = this.getDailyRevenue(b, column);
            }
            if (Number(valueA) && Number(valueB)) {
                valueA = Number(valueA);
                valueB = Number(valueB);
            }
            if (this.currentSortOrder === 'asc') {
                return valueA < valueB ? -1 : valueA > valueB ? 1 : 0;
            } else {
                return valueA > valueB ? -1 : valueA < valueB ? 1 : 0;
            }
        },
        formatDistance(distance) {
            return round2d(distance / 1000).format() + ' km';
        },
        formatCurrency(amount) {
            return round2d(amount / 100).format() + ' €';
        },
        formatPercentage(amount) {
            return round2d(amount).format() + '%';
        },
        formatAverage(amount) {
            return round2d(amount * 10).format() + ' €';
        },

        getDailyRevenue({ driverNumber }, day) {
            if (this.dailyRevenue[driverNumber] && this.dailyRevenue[driverNumber][day]) {
                return this.dailyRevenue[driverNumber][day];
            }
            const shifts =
                this.shiftsGroupedByDriver[driverNumber] || this.shiftsGroupedByCar[driverNumber];
            const dailyShifts = shifts.filter(shift => {
                const trips = shift.trips.filter(trip => {
                    const tripStart = new Date(trip.startAt);
                    return tripStart.getDate() === day.getDate();
                });

                return trips.length > 0;
            });
            const dailyRevenue = dailyShifts.reduce(
                (sum, shift) => sum + shift.finalTotalAmount,
                0,
            );
            if (!this.dailyRevenue[driverNumber]) {
                this.dailyRevenue[driverNumber] = {};
            }

            this.dailyRevenue[driverNumber][day] = dailyRevenue;
            return dailyRevenue;
        },
        revenueClass(amount) {
            if (amount === 0) return 'red';
            if (amount < 5000) return 'orange';
            if (amount < 10000) return 'yellow';
            return 'green';
        },
        averageRevenuePerKmClass(amount) {
            const average = Number(amount) * 10;
            if (average < 1.1) return 'red';
        },
        performanceClass(amount) {
            const performance = Number(amount);
            if (performance < 0.4) return 'red';
            if (performance < 0.5) return 'orange';
            if (performance < 0.6) return 'yellow';
            return 'green';
        },
        emptyPercentageClass(amount) {
            const percentage = amount;
            if (percentage === 0) return '';
            if (percentage < 0.4) return 'green';
            if (percentage < 0.5) return 'yellow';
            if (percentage < 0.6) return 'orange';
            return 'red';
        },
    },
};
</script>

<style scoped lang="scss">
.analytics-table {
    width: 100%;
    font-size: 0.75rem;
    table-layout: fixed;
    overflow: auto;
    max-height: calc(100vh - 280px);
    color: var(--color-text-black);
    border-radius: 8px;
    background-color: white;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);

    @media (max-width: breakpoint(tabletPortrait)) {
        max-height: calc(100vh - 200px);
        margin-top: 0px;
    }

    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;
        border: none;
        border-radius: 8px;

        th,
        td {
            padding: 12px 14px;
            border-bottom: 1px solid rgba(0, 0, 0, 0.08);
            text-align: right;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: background-color 0.15s ease;

            @media (max-width: breakpoint(tabletPortrait)) {
                max-width: 100px;
                padding: 8px;
            }
        }

        th {
            position: sticky;
            top: 0;
            z-index: 10;
            vertical-align: middle;
            text-align: left;
            background-color: #f8fafc;
            font-weight: 600;
            font-size: 0.75rem;
            color: #475569;
            letter-spacing: 0.025em;
            text-transform: uppercase;
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);

            &:first-child {
                left: 0;
                z-index: 20;
                border-top-left-radius: 8px;
            }

            &:hover {
                cursor: pointer;
                background-color: #f1f5f9;
            }

            .header-content {
                display: flex;
                align-items: center;
                justify-content: space-between;
            }

            .sort-icon {
                font-size: 1rem;
                opacity: 0.4;
                transition: all 0.2s ease;
            }

            &.isSorted {
                background-color: #e2e8f0;

                .sort-icon {
                    opacity: 1;
                    color: #0ea5e9;
                    transform: translateY(0);
                }
            }

            &.asc .sort-icon {
                transform: rotate(180deg);
            }
        }

        tbody tr {
            transition: background-color 0.15s ease;

            &:hover {
                background-color: #f8fafc;
            }

            &:last-child td {
                border-bottom: none;
            }
        }

        .name-cell {
            position: sticky;
            left: 0;
            z-index: 5;
            background-color: white;
            font-weight: 600;
            color: #1e293b;
            border-right: 1px solid rgba(0, 0, 0, 0.06);
            text-align: left;

            /* Ensure hover state maintains proper background */
            tr:hover & {
                background-color: #f8fafc;
            }
        }

        .metric-cell {
            font-variant-numeric: tabular-nums;
            text-align: right;
            font-weight: 500;
        }

        .day-cell {
            font-size: 0.75rem;
            font-variant-numeric: tabular-nums;
            text-align: right;
            font-weight: 500;
        }
    }

    .red {
        background-color: rgba(248, 113, 113, 0.2);
        color: #b91c1c;
        font-weight: 600;
        border-bottom-color: rgba(248, 113, 113, 0.3) !important;
    }

    .orange {
        background-color: rgba(251, 146, 60, 0.2);
        color: #c2410c;
        font-weight: 600;
        border-bottom-color: rgba(251, 146, 60, 0.3) !important;
    }

    .yellow {
        background-color: rgba(250, 204, 21, 0.2);
        color: #854d0e;
        font-weight: 600;
        border-bottom-color: rgba(250, 204, 21, 0.3) !important;
    }

    .green {
        background-color: rgba(34, 197, 94, 0.2);
        color: #15803d;
        font-weight: 600;
        border-bottom-color: rgba(34, 197, 94, 0.3) !important;
    }
}

.virtual-scroller {
    width: 100%;
}
</style>
