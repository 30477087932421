var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "revenue-trends"
  }, [_c('div', {
    staticClass: "chart-header"
  }, [_c('h3', [_vm._v("Umsatz- & Nutzungstrends")]), _c('div', {
    staticClass: "chart-controls"
  }, _vm._l(_vm.timeRangePeriods, function (period) {
    return _c('Button', {
      key: period.value,
      class: ['period-button', _vm.selectedPeriod === period.value ? 'active' : ''],
      attrs: {
        "size": "small"
      },
      on: {
        "onClick": function onClick() {
          return _vm.setTimeRange(period.value);
        }
      }
    }, [_vm._v(" " + _vm._s(period.label) + " ")]);
  }), 1)]), _c('div', {
    staticClass: "trend-summary"
  }, [_c('div', {
    staticClass: "trend-card"
  }, [_vm._m(0), _c('div', {
    staticClass: "trend-data"
  }, [_c('div', {
    staticClass: "trend-label"
  }, [_vm._v("Durchschn. Tagesumsatz")]), _c('div', {
    staticClass: "trend-value"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.avgDailyRevenue)))]), _c('div', {
    staticClass: "trend-change",
    class: _vm.revenueTrend.class
  }, [_vm._v(" " + _vm._s(_vm.revenueTrend.text) + " "), _c('i', {
    staticClass: "ri-arrow-up-line trend-icon",
    class: {
      'ri-arrow-down-line': _vm.revenueTrend.class === 'negative'
    }
  })])])]), _c('div', {
    staticClass: "trend-card"
  }, [_vm._m(1), _c('div', {
    staticClass: "trend-data"
  }, [_c('div', {
    staticClass: "trend-label"
  }, [_vm._v("Geschäftigster Tag")]), _c('div', {
    staticClass: "trend-value"
  }, [_vm._v(_vm._s(_vm.busiestDay.day))]), _c('div', {
    staticClass: "trend-sub"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.busiestDay.revenue)))])])]), _c('div', {
    staticClass: "trend-card"
  }, [_vm._m(2), _c('div', {
    staticClass: "trend-data"
  }, [_c('div', {
    staticClass: "trend-label"
  }, [_vm._v("Ruhigster Tag")]), _c('div', {
    staticClass: "trend-value"
  }, [_vm._v(_vm._s(_vm.slowestDay.day))]), _c('div', {
    staticClass: "trend-sub"
  }, [_vm._v(_vm._s(_vm.formatCurrency(_vm.slowestDay.revenue)))])])]), _c('div', {
    staticClass: "trend-card"
  }, [_vm._m(3), _c('div', {
    staticClass: "trend-data"
  }, [_c('div', {
    staticClass: "trend-label"
  }, [_vm._v("Wachstum")]), _c('div', {
    staticClass: "trend-value"
  }, [_vm._v(_vm._s(_vm.growthRate) + "%")]), _c('div', {
    staticClass: "trend-sub"
  }, [_vm._v("gegenüber Vorperiode")])])])]), _c('div', {
    staticClass: "chart-container"
  }, [_vm.loading ? _c('div', {
    staticClass: "chart-loader"
  }, [_c('i', {
    staticClass: "ri-loader-4-line spin"
  }), _c('span', [_vm._v("Lade Daten...")])]) : _c('ChartV2', {
    attrs: {
      "title": "Täglicher Umsatz",
      "type": "bar",
      "values": _vm.revenueChartData,
      "halfHeight": true
    }
  })], 1), _c('div', {
    staticClass: "chart-container"
  }, [_vm.loading ? _c('div', {
    staticClass: "chart-loader"
  }, [_c('i', {
    staticClass: "ri-loader-4-line spin"
  }), _c('span', [_vm._v("Lade Daten...")])]) : _c('ChartV2', {
    attrs: {
      "title": "Leerkilometer-Quote",
      "type": "bar",
      "values": _vm.utilizationChartData,
      "color": "blue",
      "halfHeight": true
    }
  })], 1)]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trend-icon"
  }, [_c('i', {
    staticClass: "ri-currency-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trend-icon"
  }, [_c('i', {
    staticClass: "ri-bar-chart-grouped-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trend-icon"
  }, [_c('i', {
    staticClass: "ri-bar-chart-line"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "trend-icon"
  }, [_c('i', {
    staticClass: "ri-line-chart-line"
  })]);
}]

export { render, staticRenderFns }