<template>
    <div class="performance-chart">
        <div class="chart-header">
            <h3>Performance-Vergleich</h3>
            <div class="metric-selector">
                <Button
                    v-for="metric in metrics"
                    :key="metric.id"
                    :class="['metric-button', selectedMetric === metric.id ? 'active' : '']"
                    size="small"
                    @onClick="() => handleMetricChange(metric.id)"
                >
                    {{ metric.label }}
                </Button>
            </div>
        </div>

        <div class="chart-container" ref="chartContainer">
            <div class="chart-loader" v-if="loading">
                <i class="ri-loader-4-line spin"></i>
                <span>Lade Daten...</span>
            </div>
        </div>

        <div class="chart-legend">
            <div class="legend-item" v-for="(item, index) in topPerformers" :key="index">
                <div class="performer-rank">{{ index + 1 }}</div>
                <div class="legend-marker" :style="{ backgroundColor: colorScale(index) }"></div>
                <div class="legend-text">
                    <div class="name">{{ item.name }}</div>
                    <div class="value">{{ formatValue(item[selectedMetric]) }}</div>
                </div>
                <div class="trend-indicator" :class="getTrendClass(item.trend)">
                    <i :class="getTrendIcon(item.trend)"></i>
                    <span>{{ getTrendText(item.trend) }}</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as d3 from 'd3';
import Button from '@/components/widgets/Button';
import { round2d } from '@/lib/helper';

export default {
    name: 'PerformanceChart',
    components: {
        Button,
    },
    props: {
        data: {
            type: Array,
            required: true,
        },
        option: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            selectedMetric: 'revenue',
            chart: null,
            colorScale: d3.scaleOrdinal(d3.schemeCategory10),
            loading: true,
            metrics: [
                { id: 'revenue', label: 'Umsatz' },
                { id: 'revenuePerKm', label: 'Umsatz/Km' },
                { id: 'emptyPercentage', label: 'Leerquote' },
                { id: 'tripCount', label: 'Fahrten' },
            ],
        };
    },
    computed: {
        sortedData() {
            const data = [...this.data];
            // Add mock trend data (would be replaced with real data in production)
            data.forEach(item => {
                item.trend = Math.random() > 0.5 ? Math.random() * 15 : -Math.random() * 15;
            });
            return data.sort((a, b) => b[this.selectedMetric] - a[this.selectedMetric]);
        },
        topPerformers() {
            return this.sortedData.slice(0, 5);
        },
    },
    watch: {
        data: {
            handler() {
                this.loading = true;
                this.$nextTick(() => {
                    this.updateChart();
                    setTimeout(() => {
                        this.loading = false;
                    }, 500);
                });
            },
            immediate: true,
        },
        selectedMetric() {
            this.loading = true;
            this.$nextTick(() => {
                this.updateChart();
                setTimeout(() => {
                    this.loading = false;
                }, 300);
            });
        },
    },
    methods: {
        handleMetricChange(metric) {
            this.selectedMetric = metric;
        },

        formatValue(value) {
            if (this.selectedMetric === 'revenue') {
                return round2d(value).format() + ' €';
            } else if (this.selectedMetric === 'revenuePerKm') {
                return round2d(value).format() + ' €/km';
            } else if (this.selectedMetric === 'emptyPercentage') {
                return round2d(value).format() + '%';
            } else {
                return Math.round(value);
            }
        },

        getTrendClass(trend) {
            if (!trend || trend === 0) return 'neutral';
            return trend > 0 ? 'positive' : 'negative';
        },

        getTrendIcon(trend) {
            if (!trend || trend === 0) return 'ri-arrow-right-line';
            return trend > 0 ? 'ri-arrow-up-line' : 'ri-arrow-down-line';
        },

        getTrendText(trend) {
            if (!trend || trend === 0) return '0%';
            return `${Math.abs(Math.round(trend))}%`;
        },

        createChart() {
            const container = this.$refs.chartContainer;
            if (!container) return;

            const width = container.clientWidth;
            const height = 400;
            const margin = { top: 30, right: 30, bottom: 70, left: 80 };
            const innerWidth = width - margin.left - margin.right;
            const innerHeight = height - margin.top - margin.bottom;

            // Clear previous chart
            d3.select(container)
                .selectAll('svg')
                .remove();

            // Create SVG
            const svg = d3
                .select(container)
                .append('svg')
                .attr('width', width)
                .attr('height', height)
                .attr('class', 'performance-chart-svg')
                .append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`);

            // Add background grid
            svg.append('g')
                .attr('class', 'grid-lines')
                .attr('opacity', 0.1);

            // Get data for chart
            const chartData = this.sortedData.slice(0, 10); // Top 10

            // Create scales
            const x = d3
                .scaleBand()
                .domain(chartData.map(d => d.name))
                .range([0, innerWidth])
                .padding(0.3);

            const y = d3
                .scaleLinear()
                .domain([0, d3.max(chartData, d => d[this.selectedMetric]) * 1.1])
                .nice()
                .range([innerHeight, 0]);

            // Add background
            svg.append('rect')
                .attr('width', innerWidth)
                .attr('height', innerHeight)
                .attr('fill', '#f8fafc')
                .attr('rx', 6);

            // Add grid lines
            svg.select('.grid-lines')
                .selectAll('line.grid-line')
                .data(y.ticks(5))
                .enter()
                .append('line')
                .attr('class', 'grid-line')
                .attr('x1', 0)
                .attr('x2', innerWidth)
                .attr('y1', d => y(d))
                .attr('y2', d => y(d))
                .attr('stroke', '#000')
                .attr('stroke-dasharray', '3,3');

            // Draw axes
            svg.append('g')
                .attr('class', 'x-axis')
                .attr('transform', `translate(0,${innerHeight})`)
                .call(d3.axisBottom(x))
                .selectAll('text')
                .attr('transform', 'rotate(-45)')
                .style('text-anchor', 'end')
                .attr('dx', '-.8em')
                .attr('dy', '.15em')
                .style('font-size', '12px')
                .style('font-weight', '500');

            svg.append('g')
                .attr('class', 'y-axis')
                .call(d3.axisLeft(y).ticks(5))
                .selectAll('text')
                .style('font-size', '12px');

            // Add axis labels
            svg.append('text')
                .attr('class', 'y-axis-label')
                .attr('transform', 'rotate(-90)')
                .attr('y', -60)
                .attr('x', -innerHeight / 2)
                .attr('text-anchor', 'middle')
                .style('font-size', '12px')
                .style('font-weight', '600')
                .text(this.getYAxisLabel());

            // Draw bars with gradient
            const defs = svg.append('defs');

            // Create gradient for each bar
            chartData.forEach((d, i) => {
                const gradientId = `bar-gradient-${i}`;
                const gradient = defs
                    .append('linearGradient')
                    .attr('id', gradientId)
                    .attr('gradientUnits', 'userSpaceOnUse')
                    .attr('x1', 0)
                    .attr('y1', y(0))
                    .attr('x2', 0)
                    .attr('y2', y(d[this.selectedMetric]));

                gradient
                    .append('stop')
                    .attr('offset', '0%')
                    .attr('stop-color', this.colorScale(i))
                    .attr('stop-opacity', 0.7);

                gradient
                    .append('stop')
                    .attr('offset', '100%')
                    .attr('stop-color', this.colorScale(i))
                    .attr('stop-opacity', 1);
            });

            // Draw bars
            svg.selectAll('.bar')
                .data(chartData)
                .enter()
                .append('rect')
                .attr('class', 'bar')
                .attr('rx', 4)
                .attr('x', d => x(d.name))
                .attr('y', d => y(d[this.selectedMetric]))
                .attr('width', x.bandwidth())
                .attr('height', d => innerHeight - y(d[this.selectedMetric]))
                .attr('fill', (d, i) => `url(#bar-gradient-${i})`)
                .attr('stroke', (d, i) => this.colorScale(i))
                .attr('stroke-width', 1)
                .on('mouseover', (event, d) => {
                    // Show tooltip
                    d3.select(event.currentTarget)
                        .transition()
                        .duration(200)
                        .attr('opacity', 0.8)
                        .attr('stroke-width', 2);

                    const tooltip = svg
                        .append('g')
                        .attr('class', 'tooltip')
                        .attr(
                            'transform',
                            `translate(${x(d.name) + x.bandwidth() / 2}, ${y(
                                d[this.selectedMetric],
                            ) - 15})`,
                        );

                    const tooltipBg = tooltip
                        .append('rect')
                        .attr('rx', 4)
                        .attr('fill', '#333')
                        .attr('opacity', 0.9);

                    const tooltipText = tooltip
                        .append('text')
                        .attr('text-anchor', 'middle')
                        .attr('fill', 'white')
                        .attr('dy', '0.32em')
                        .style('font-size', '12px')
                        .text(this.formatValue(d[this.selectedMetric]));

                    const textBBox = tooltipText.node().getBBox();
                    tooltipBg
                        .attr('x', textBBox.x - 8)
                        .attr('y', textBBox.y - 4)
                        .attr('width', textBBox.width + 16)
                        .attr('height', textBBox.height + 8);
                })
                .on('mouseout', event => {
                    d3.select(event.currentTarget)
                        .transition()
                        .duration(200)
                        .attr('opacity', 1)
                        .attr('stroke-width', 1);

                    svg.selectAll('.tooltip').remove();
                });

            // Add value labels above bars
            svg.selectAll('.bar-label')
                .data(chartData)
                .enter()
                .append('text')
                .attr('class', 'bar-label')
                .attr('x', d => x(d.name) + x.bandwidth() / 2)
                .attr('y', d => y(d[this.selectedMetric]) - 5)
                .attr('text-anchor', 'middle')
                .style('font-size', '11px')
                .style('font-weight', '600')
                .text(d => this.formatValue(d[this.selectedMetric]));

            // Add chart title
            svg.append('text')
                .attr('class', 'chart-title')
                .attr('x', innerWidth / 2)
                .attr('y', -10)
                .attr('text-anchor', 'middle')
                .style('font-size', '14px')
                .style('font-weight', 'bold')
                .text(this.getChartTitle());

            this.chart = { svg, x, y, innerHeight, innerWidth, margin };
        },

        updateChart() {
            if (!this.$refs.chartContainer) return;

            if (!this.chart) {
                this.createChart();
            } else {
                // Update existing chart
                const chartData = this.sortedData.slice(0, 10);
                const { svg, x, y, innerHeight } = this.chart;

                // Update y scale
                y.domain([0, d3.max(chartData, d => d[this.selectedMetric]) * 1.1]).nice();

                // Update y axis
                svg.select('.y-axis')
                    .transition()
                    .duration(500)
                    .call(d3.axisLeft(y).ticks(5));

                // Update y axis label
                svg.select('.y-axis-label').text(this.getYAxisLabel());

                // Update chart title
                svg.select('.chart-title').text(this.getChartTitle());

                // Update grid lines
                svg.select('.grid-lines')
                    .selectAll('line.grid-line')
                    .remove();

                svg.select('.grid-lines')
                    .selectAll('line.grid-line')
                    .data(y.ticks(5))
                    .enter()
                    .append('line')
                    .attr('class', 'grid-line')
                    .attr('x1', 0)
                    .attr('x2', innerWidth)
                    .attr('y1', d => y(d))
                    .attr('y2', d => y(d))
                    .attr('stroke', '#000')
                    .attr('stroke-dasharray', '3,3');

                // Update bars
                const bars = svg.selectAll('.bar').data(chartData);

                // Exit
                bars.exit().remove();

                // Update
                bars.transition()
                    .duration(500)
                    .attr('y', d => y(d[this.selectedMetric]))
                    .attr('height', d => innerHeight - y(d[this.selectedMetric]));

                // Enter
                bars.enter()
                    .append('rect')
                    .attr('class', 'bar')
                    .attr('rx', 4)
                    .attr('x', d => x(d.name))
                    .attr('y', d => y(d[this.selectedMetric]))
                    .attr('width', x.bandwidth())
                    .attr('height', d => innerHeight - y(d[this.selectedMetric]))
                    .attr('fill', (d, i) => this.colorScale(i))
                    .attr('stroke', (d, i) => this.colorScale(i))
                    .attr('stroke-width', 1);

                // Update bar labels
                const labels = svg.selectAll('.bar-label').data(chartData);

                // Exit
                labels.exit().remove();

                // Update
                labels
                    .transition()
                    .duration(500)
                    .attr('y', d => y(d[this.selectedMetric]) - 5)
                    .text(d => this.formatValue(d[this.selectedMetric]));

                // Enter
                labels
                    .enter()
                    .append('text')
                    .attr('class', 'bar-label')
                    .attr('x', d => x(d.name) + x.bandwidth() / 2)
                    .attr('y', d => y(d[this.selectedMetric]) - 5)
                    .attr('text-anchor', 'middle')
                    .style('font-size', '11px')
                    .style('font-weight', '600')
                    .text(d => this.formatValue(d[this.selectedMetric]));
            }
        },

        getYAxisLabel() {
            switch (this.selectedMetric) {
                case 'revenue':
                    return 'Umsatz (€)';
                case 'revenuePerKm':
                    return 'Umsatz pro Kilometer (€/km)';
                case 'emptyPercentage':
                    return 'Leerquote (%)';
                case 'tripCount':
                    return 'Anzahl der Fahrten';
                default:
                    return '';
            }
        },

        getChartTitle() {
            const metricLabel = this.metrics.find(m => m.id === this.selectedMetric).label;
            const entityType = this.option.id === 'car' ? 'Fahrzeuge' : 'Fahrer';
            return `Top 10 ${entityType} nach ${metricLabel}`;
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.createChart();

            // Add resize handler
            this.resizeHandler = () => {
                this.chart = null;
                this.createChart();
            };

            window.addEventListener('resize', this.resizeHandler);

            setTimeout(() => {
                this.loading = false;
            }, 500);
        });
    },
    beforeDestroy() {
        if (this.resizeHandler) {
            window.removeEventListener('resize', this.resizeHandler);
        }
    },
};
</script>

<style lang="scss">
.performance-chart {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-bottom: 30px;
    position: relative;
    overflow: hidden;

    .chart-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
        }

        h3 {
            font-size: 1.2rem;
            font-weight: 600;
            margin: 0;
            color: #1e293b;
        }
    }

    .metric-selector {
        display: flex;
        gap: 8px;
        flex-wrap: wrap;

        .metric-button {
            background-color: #f1f5f9;
            color: #64748b;
            border: none;
            font-size: 0.8rem;
            padding: 6px 12px;
            border-radius: 20px;
            font-weight: 500;
            transition: all 0.2s ease;

            &:hover {
                background-color: #e2e8f0;
            }

            &.active {
                background-color: #0ea5e9;
                color: white;
            }
        }
    }

    .chart-container {
        height: 400px;
        position: relative;
        margin-bottom: 20px;
        overflow: hidden;

        .chart-loader {
            position: absolute;
            inset: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(2px);
            z-index: 5;

            i {
                font-size: 2rem;
                color: #0ea5e9;
                margin-bottom: 10px;
            }

            span {
                font-size: 0.9rem;
                color: #64748b;
                font-weight: 500;
            }

            .spin {
                animation: spin 1.5s linear infinite;
            }

            @keyframes spin {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }
        }
    }

    .performance-chart-svg {
        .x-axis,
        .y-axis {
            path.domain {
                stroke: #cbd5e1;
            }

            .tick line {
                stroke: #cbd5e1;
            }

            text {
                fill: #64748b;
            }
        }
    }

    .chart-legend {
        display: flex;
        flex-direction: column;
        gap: 8px;
        margin-top: 10px;

        .legend-item {
            display: flex;
            align-items: center;
            padding: 8px 12px;
            background-color: #f8fafc;
            border-radius: 8px;

            &:hover {
                background-color: #f1f5f9;
            }

            .performer-rank {
                width: 24px;
                height: 24px;
                border-radius: 50%;
                background-color: #0ea5e9;
                color: white;
                display: flex;
                align-items: center;
                justify-content: center;
                font-weight: 600;
                font-size: 0.8rem;
                margin-right: 10px;
            }

            .legend-marker {
                width: 12px;
                height: 12px;
                border-radius: 3px;
                margin-right: 10px;
            }

            .legend-text {
                flex: 1;

                .name {
                    font-weight: 500;
                    font-size: 0.9rem;
                    color: #334155;
                }

                .value {
                    font-size: 0.875rem;
                    font-weight: 600;
                    color: #0f172a;
                }
            }

            .trend-indicator {
                display: flex;
                align-items: center;
                font-size: 0.8rem;
                font-weight: 600;
                padding: 3px 8px;
                border-radius: 12px;

                i {
                    margin-right: 4px;
                }

                &.positive {
                    background-color: #dcfce7;
                    color: #15803d;
                }

                &.negative {
                    background-color: #fee2e2;
                    color: #b91c1c;
                }

                &.neutral {
                    background-color: #e2e8f0;
                    color: #64748b;
                }
            }
        }
    }
}
</style>
