<template>
    <div class="Analytics" :class="{ 'is-loading': isFetchingData }">
        <Toolbar>
            <div class="toolbar-controls">
                <Datepicker @onChange="handleDateChange" :startDate="selectedFrom" monthPicker />
                <Dropdown
                    :items="dropdownValues"
                    :selected="selectedValue && selectedValue.value"
                    variant="single-column"
                    :hasRemoveIcon="false"
                    placeholder="Kategorie auswählen"
                    @onItemSelect="handleDropdownSelect"
                />
                <Button size="small" @onClick="handleQuery" :isLoading="isFetchingData">
                    Anzeigen
                </Button>
            </div>
            <div class="toolbar-actions">
                <!-- <Dropdown
                    :items="viewOptions"
                    :selected="selectedView"
                    variant="single-column"
                    :hasRemoveIcon="false"
                    placeholder="Ansicht"
                    @onItemSelect="handleViewChange"
                /> -->
                <Download title="Download CSV" type="csv" @onDownload="handleDownload('csv')" />
            </div>
        </Toolbar>

        <!-- Dashboard Summary Cards -->
        <div class="OverviewCards" v-if="!isFetchingData && shifts.length > 0">
            <Card class="OverviewCard" v-for="metric in summaryMetrics" :key="metric.id">
                <div class="metric-content">
                    <div class="metric-icon" :class="metric.trend">
                        <i :class="getMetricIcon(metric)"></i>
                    </div>
                    <div class="metric-details">
                        <h3>{{ metric.label }}</h3>
                        <div class="metric-value">{{ metric.value }}</div>
                        <div class="metric-change" :class="metric.trend">
                            <span>{{ metric.changeText }}</span>
                            <i
                                class="trend-icon ri-arrow-up-line"
                                :class="{
                                    'ri-arrow-down-line': metric.trend === 'negative',
                                }"
                            ></i>
                        </div>
                    </div>
                </div>
            </Card>
        </div>

        <!-- View Toggle -->
        <div class="view-container" v-if="!isFetchingData && shifts.length > 0">
            <div v-if="selectedView === 'table'">
                <AnalyticsTable
                    :shifts="shifts"
                    :startAt="startAt"
                    :endAt="endAt"
                    :option="selectedValue"
                    @onCVSChange="handleCSVChange"
                    @onRowHover="handleRowHover"
                    @onDriverClick="handleDriverClick"
                />
            </div>

            <div v-else-if="selectedView === 'performance'">
                <PerformanceChart :data="performanceData" :option="selectedValue" />
            </div>

            <div v-else-if="selectedView === 'trends'">
                <RevenueTrends :shifts="shifts" :startAt="startAt" :endAt="endAt" />
            </div>

            <div v-else-if="selectedView === 'heatmap'">
                <ActivityHeatmap :shifts="shifts" :startAt="startAt" :endAt="endAt" />
            </div>

            <div v-else-if="selectedView === 'insights'">
                <BusinessInsights
                    :shifts="shifts"
                    :startAt="startAt"
                    :endAt="endAt"
                    :option="selectedValue"
                />
            </div>
        </div>

        <EmptyState v-if="!isFetchingData && shifts.length === 0" />
    </div>
</template>

<script>
import axios from 'axios';
import { endOfMonth, formatISO, startOfMonth, subMonths, format, isWithinInterval } from 'date-fns';
import { round2d } from '@/lib/helper';

import Datepicker from '@/components/widgets/Datepicker';
import Button from '@/components/widgets/Button';
import Download from '@/components/widgets/Download';
import Dropdown from '@/components/widgets/Dropdown';
import Headline from '@/components/Headline';
import Toolbar from '@/components/Toolbar';
import Card from '@/components/Card.vue';
import EmptyState from '@/components/EmptyState.vue';

import AnalyticsTable from './components/AnalyticsTable';
import PerformanceChart from './components/PerformanceChart';
import RevenueTrends from './components/RevenueTrends';
import ActivityHeatmap from './components/ActivityHeatmap';
import BusinessInsights from './components/BusinessInsights';

export default {
    name: 'Analytics',
    components: {
        Card,
        AnalyticsTable,
        Datepicker,
        Button,
        Download,
        Dropdown,
        Headline,
        Toolbar,
        EmptyState,
        PerformanceChart,
        RevenueTrends,
        ActivityHeatmap,
        BusinessInsights,
    },
    data() {
        return {
            dropdownValues: [{ id: 'driver', value: 'Fahrer' }, { id: 'car', value: 'Fahrzeuge' }],
            viewOptions: [
                { id: 'table', value: 'Tabelle' },
                { id: 'performance', value: 'Performance' },
                { id: 'trends', value: 'Trends' },
                { id: 'heatmap', value: 'Aktivitäts-Heatmap' },
                { id: 'insights', value: 'Business Insights' },
            ],
            selectedValue: { id: 'driver', value: 'Fahrer' },
            selectedView: 'table',
            isFetchingData: false,
            shifts: [],
            previousPeriodShifts: [],
            drivers: JSON.parse(localStorage.getItem('drivers') || '[]').filter(d => d.isVisible),
            cars: JSON.parse(localStorage.getItem('cars') || '[]'),
            selectedFrom: startOfMonth(new Date()),
            selectedTo: endOfMonth(new Date()),
            totalRevenue: 0,
            previousPeriodRevenue: 0,
            csv: null,
            startAt: startOfMonth(new Date()),
            endAt: endOfMonth(new Date()),
            summaryMetrics: [],
            selectedMetric: null,
            hoveredEntity: null,
        };
    },

    computed: {
        performanceData() {
            if (this.selectedValue.id === 'driver') {
                return this.processDriverPerformanceData();
            } else {
                return this.processCarPerformanceData();
            }
        },

        totalDistance() {
            return this.shifts.reduce((sum, shift) => sum + shift.totalDistance, 0) / 1000;
        },

        averageRevenuePerKm() {
            const totalDistance = this.totalDistance || 1; // Avoid division by zero
            return this.totalRevenue / 100 / totalDistance;
        },

        totalEmptyDistance() {
            return (
                this.shifts.reduce(
                    (sum, shift) => sum + (shift.totalDistance - shift.hiredDistance),
                    0,
                ) / 1000
            );
        },

        emptyPercentage() {
            return (this.totalEmptyDistance / this.totalDistance) * 100 || 0;
        },

        operatingHours() {
            return this.shifts.reduce((sum, shift) => {
                const startTime = new Date(shift.startAt).getTime();
                const endTime = new Date(shift.endAt).getTime();
                return sum + (endTime - startTime) / (1000 * 60 * 60);
            }, 0);
        },

        revenuePerHour() {
            return this.totalRevenue / 100 / (this.operatingHours || 1);
        },

        busyHours() {
            const hours = Array(24).fill(0);

            this.shifts.forEach(shift => {
                shift.trips.forEach(trip => {
                    const hour = new Date(trip.startAt).getHours();
                    hours[hour]++;
                });
            });

            const maxActivity = Math.max(...hours);
            const busiestHourIndex = hours.indexOf(maxActivity);

            return {
                busiestHour: busiestHourIndex,
                distribution: hours,
            };
        },
    },

    methods: {
        handleViewChange({ item }) {
            this.selectedView = item.id;
            // Update summary metrics when view changes
            this.generateSummaryMetrics();
        },

        handleDropdownSelect({ item }) {
            this.selectedValue = item;
        },

        handleDriverSelect({ item }) {
            this.selectedEmployee = item;
        },

        handleCarSelect({ item }) {
            this.selectedCar = item;
        },

        handleDateChange({ from, to }) {
            this.selectedFrom = from;
            this.selectedTo = to || endOfMonth(from);
        },

        handleDownload() {
            // Create a Blob and trigger download
            const blob = new Blob([this.csv], { type: 'text/csv;charset=utf-8;' });
            const link = document.createElement('a');
            const url = URL.createObjectURL(blob);
            link.setAttribute('href', url);
            link.setAttribute(
                'download',
                'analytics_' + format(this.startAt, 'yyyy-MM-dd') + '.csv',
            );
            link.style.visibility = 'hidden';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        },

        handleRowHover(entity) {
            this.hoveredEntity = entity;
        },

        handleDriverClick(driver) {
            // Open detailed driver view
            this.$router.push({
                name: 'DriverDetail',
                params: { id: driver.driverNumber },
                query: {
                    from: format(this.startAt, 'yyyy-MM-dd'),
                    to: format(this.endAt, 'yyyy-MM-dd'),
                },
            });
        },

        handleCSVChange(csv) {
            this.csv = csv;
        },

        getMetricIcon(metric) {
            const iconMap = {
                revenue: 'ri-money-dollar-circle-line',
                distance: 'ri-route-line',
                efficiency: 'ri-line-chart-line',
                utilization: 'ri-gas-station-line',
                performance: 'ri-speed-line',
            };

            return iconMap[metric.id] || 'ri-bar-chart-line';
        },

        async fetchPreviousPeriodData() {
            const periodLength = this.endAt.getTime() - this.startAt.getTime();
            const previousStart = new Date(this.startAt.getTime() - periodLength);
            const previousEnd = new Date(this.endAt.getTime() - periodLength);

            const queryData = {
                rangeStartAt: formatISO(previousStart),
                rangeEndAt: formatISO(previousEnd),
            };

            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/shifts`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });

                this.previousPeriodShifts = result.data.filter(s => !s.isSystemDriver);
                this.previousPeriodRevenue = this.calculateTotalRevenue(this.previousPeriodShifts);

                this.generateSummaryMetrics();
            } catch (error) {
                console.error('Error fetching previous period data:', error);
            }
        },

        async handleQuery() {
            this.isFetchingData = true;
            const queryData = {
                driverNumber: this.selectedEmployee ? this.selectedEmployee.id : null,
                licenseNumber: this.selectedCar ? this.selectedCar.id : null,
                rangeStartAt: formatISO(this.selectedFrom),
                rangeEndAt: formatISO(this.selectedTo || this.selectedFrom),
            };

            try {
                const url = `${process.env.VUE_APP_API_BASE_URL}/cpanel/shifts`;
                const result = await axios.post(url, queryData, {
                    withCredentials: true,
                });

                this.$emit('onFinishLoading');
                this.startAt = this.selectedFrom;
                this.endAt = this.selectedTo || this.selectedFrom;
                this.shifts = result.data.filter(s => !s.isSystemDriver);
                this.totalRevenue = this.calculateTotalRevenue(this.shifts);

                // Fetch previous period data for comparison
                await this.fetchPreviousPeriodData();
            } catch (error) {
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },

        calculateTotalRevenue(shifts) {
            return shifts.reduce((acc, shift) => acc + shift.finalTotalAmount, 0);
        },

        generateSummaryMetrics() {
            const previousRevenue = this.previousPeriodRevenue || 1; // Avoid division by zero
            const revenueChange = ((this.totalRevenue - previousRevenue) / previousRevenue) * 100;

            // Calculate previous period metrics
            const previousDistance =
                this.previousPeriodShifts.reduce((sum, shift) => sum + shift.totalDistance, 0) /
                1000;
            const distanceChange =
                ((this.totalDistance - previousDistance) / previousDistance) * 100;

            const previousHiredDistance =
                this.previousPeriodShifts.reduce((sum, shift) => sum + shift.hiredDistance, 0) /
                1000;

            const previousEmptyDistance =
                this.previousPeriodShifts.reduce(
                    (sum, shift) => sum + (shift.totalDistance - shift.hiredDistance),
                    0,
                ) / 1000;

            const previousEmptyPercentage = (previousEmptyDistance / previousDistance) * 100 || 0;

            const emptyPercentageChange = this.emptyPercentage - previousEmptyPercentage;

            const previousRevenuePerKm = previousRevenue / 100 / (previousDistance || 1);
            const efficiencyChange =
                ((this.averageRevenuePerKm - previousRevenuePerKm) / previousRevenuePerKm) * 100;

            // Calculate trip-related metrics
            const totalTrips = this.shifts.reduce((sum, shift) => sum + shift.trips.length, 0);
            const previousTotalTrips = this.previousPeriodShifts.reduce(
                (sum, shift) => sum + shift.trips.length,
                0,
            );
            const tripChange =
                ((totalTrips - previousTotalTrips) / (previousTotalTrips || 1)) * 100;

            // Calculate average trip metrics
            const avgTripRevenue = this.totalRevenue / 100 / (totalTrips || 1);
            const previousAvgTripRevenue = previousRevenue / 100 / (previousTotalTrips || 1);
            const avgTripRevenueChange =
                ((avgTripRevenue - previousAvgTripRevenue) / (previousAvgTripRevenue || 1)) * 100;

            // Base metrics that are relevant for all views
            const baseMetrics = [
                {
                    id: 'revenue',
                    label: 'Gesamtumsatz',
                    value: round2d(this.totalRevenue / 100).format() + ' €',
                    change: revenueChange,
                    changeText: round2d(Math.abs(revenueChange)).format() + '%',
                    trend: revenueChange >= 0 ? 'positive' : 'negative',
                },
                {
                    id: 'distance',
                    label: 'Gefahrene Km',
                    value: round2d(this.totalDistance).format() + ' km',
                    change: distanceChange,
                    changeText: round2d(Math.abs(distanceChange)).format() + '%',
                    trend: distanceChange >= 0 ? 'positive' : 'negative',
                },
            ];

            // View-specific metrics
            let viewSpecificMetrics = [];

            switch (this.selectedView) {
                case 'table':
                    viewSpecificMetrics = [
                        {
                            id: 'efficiency',
                            label: 'Umsatz pro Km',
                            value: round2d(this.averageRevenuePerKm).format() + ' €',
                            change: efficiencyChange,
                            changeText: round2d(Math.abs(efficiencyChange)).format() + '%',
                            trend: efficiencyChange >= 0 ? 'positive' : 'negative',
                        },
                        {
                            id: 'utilization',
                            label: 'Leerkilometer',
                            value: round2d(this.emptyPercentage).format() + '%',
                            change: emptyPercentageChange,
                            changeText: round2d(Math.abs(emptyPercentageChange)).format() + '%',
                            trend: emptyPercentageChange < 0 ? 'positive' : 'negative',
                        },
                        {
                            id: 'performance',
                            label: 'Rentabelste Stunde',
                            value: `${this.busyHours.busiestHour}:00 - ${this.busyHours
                                .busiestHour + 1}:00`,
                            changeText: 'Hotspot',
                            trend: 'neutral',
                        },
                    ];
                    break;

                case 'performance':
                    viewSpecificMetrics = [
                        {
                            id: 'efficiency',
                            label: 'Umsatz pro Km',
                            value: round2d(this.averageRevenuePerKm).format() + ' €',
                            change: efficiencyChange,
                            changeText: round2d(Math.abs(efficiencyChange)).format() + '%',
                            trend: efficiencyChange >= 0 ? 'positive' : 'negative',
                        },
                        {
                            id: 'trips',
                            label: 'Fahrten Gesamt',
                            value: totalTrips,
                            change: tripChange,
                            changeText: round2d(Math.abs(tripChange)).format() + '%',
                            trend: tripChange >= 0 ? 'positive' : 'negative',
                        },
                        {
                            id: 'trip_revenue',
                            label: 'Umsatz pro Fahrt',
                            value: round2d(avgTripRevenue).format() + ' €',
                            change: avgTripRevenueChange,
                            changeText: round2d(Math.abs(avgTripRevenueChange)).format() + '%',
                            trend: avgTripRevenueChange >= 0 ? 'positive' : 'negative',
                        },
                    ];
                    break;

                case 'trends':
                    viewSpecificMetrics = [
                        {
                            id: 'trips',
                            label: 'Fahrten Gesamt',
                            value: totalTrips,
                            change: tripChange,
                            changeText: round2d(Math.abs(tripChange)).format() + '%',
                            trend: tripChange >= 0 ? 'positive' : 'negative',
                        },
                        {
                            id: 'trip_revenue',
                            label: 'Umsatz pro Fahrt',
                            value: round2d(avgTripRevenue).format() + ' €',
                            change: avgTripRevenueChange,
                            changeText: round2d(Math.abs(avgTripRevenueChange)).format() + '%',
                            trend: avgTripRevenueChange >= 0 ? 'positive' : 'negative',
                        },
                        {
                            id: 'performance',
                            label: 'Rentabelste Stunde',
                            value: `${this.busyHours.busiestHour}:00 - ${this.busyHours
                                .busiestHour + 1}:00`,
                            changeText: 'Hotspot',
                            trend: 'neutral',
                        },
                    ];
                    break;

                case 'heatmap':
                    viewSpecificMetrics = [
                        {
                            id: 'hiredDistance',
                            label: 'Besetzt Km',
                            value:
                                round2d(
                                    this.shifts.reduce(
                                        (sum, shift) => sum + shift.hiredDistance,
                                        0,
                                    ) / 1000,
                                ).format() + ' km',
                            change:
                                ((this.shifts.reduce((sum, shift) => sum + shift.hiredDistance, 0) /
                                    1000 -
                                    previousHiredDistance) /
                                    (previousHiredDistance || 1)) *
                                100,
                            changeText:
                                round2d(
                                    Math.abs(
                                        ((this.shifts.reduce(
                                            (sum, shift) => sum + shift.hiredDistance,
                                            0,
                                        ) /
                                            1000 -
                                            previousHiredDistance) /
                                            (previousHiredDistance || 1)) *
                                            100,
                                    ),
                                ).format() + '%',
                            trend:
                                this.shifts.reduce((sum, shift) => sum + shift.hiredDistance, 0) /
                                    1000 -
                                    previousHiredDistance >=
                                0
                                    ? 'positive'
                                    : 'negative',
                        },
                        {
                            id: 'emptyDistance',
                            label: 'Leer Km',
                            value: round2d(this.totalEmptyDistance).format() + ' km',
                            change:
                                ((this.totalEmptyDistance - previousEmptyDistance) /
                                    (previousEmptyDistance || 1)) *
                                100,
                            changeText:
                                round2d(
                                    Math.abs(
                                        ((this.totalEmptyDistance - previousEmptyDistance) /
                                            (previousEmptyDistance || 1)) *
                                            100,
                                    ),
                                ).format() + '%',
                            trend:
                                this.totalEmptyDistance - previousEmptyDistance < 0
                                    ? 'positive'
                                    : 'negative',
                        },
                        {
                            id: 'utilization',
                            label: 'Leerquote',
                            value: round2d(this.emptyPercentage).format() + '%',
                            change: emptyPercentageChange,
                            changeText: round2d(Math.abs(emptyPercentageChange)).format() + '%',
                            trend: emptyPercentageChange < 0 ? 'positive' : 'negative',
                        },
                    ];
                    break;

                case 'insights':
                    viewSpecificMetrics = [
                        {
                            id: 'efficiency',
                            label: 'Umsatz pro Km',
                            value: round2d(this.averageRevenuePerKm).format() + ' €',
                            change: efficiencyChange,
                            changeText: round2d(Math.abs(efficiencyChange)).format() + '%',
                            trend: efficiencyChange >= 0 ? 'positive' : 'negative',
                        },
                        {
                            id: 'utilization',
                            label: 'Leerquote',
                            value: round2d(this.emptyPercentage).format() + '%',
                            change: emptyPercentageChange,
                            changeText: round2d(Math.abs(emptyPercentageChange)).format() + '%',
                            trend: emptyPercentageChange < 0 ? 'positive' : 'negative',
                        },
                        {
                            id: 'hours',
                            label: 'Betriebsstunden',
                            value: round2d(this.operatingHours).format() + ' h',
                            changeText: round2d(this.revenuePerHour).format() + ' €/h',
                            trend: 'neutral',
                        },
                    ];
                    break;

                default:
                    viewSpecificMetrics = [
                        {
                            id: 'efficiency',
                            label: 'Umsatz pro Km',
                            value: round2d(this.averageRevenuePerKm).format() + ' €',
                            change: efficiencyChange,
                            changeText: round2d(Math.abs(efficiencyChange)).format() + '%',
                            trend: efficiencyChange >= 0 ? 'positive' : 'negative',
                        },
                        {
                            id: 'utilization',
                            label: 'Leerkilometer',
                            value: round2d(this.emptyPercentage).format() + '%',
                            change: emptyPercentageChange,
                            changeText: round2d(Math.abs(emptyPercentageChange)).format() + '%',
                            trend: emptyPercentageChange < 0 ? 'positive' : 'negative',
                        },
                        {
                            id: 'performance',
                            label: 'Rentabelste Stunde',
                            value: `${this.busyHours.busiestHour}:00 - ${this.busyHours
                                .busiestHour + 1}:00`,
                            changeText: 'Hotspot',
                            trend: 'neutral',
                        },
                    ];
            }

            this.summaryMetrics = [...baseMetrics, ...viewSpecificMetrics];
        },

        processDriverPerformanceData() {
            // Similar to your existing calculation but formatted for D3
            const driverStats = Object.groupBy(this.shifts, ({ driver }) => driver.driverNumber);

            return Object.entries(driverStats).map(([driverNumber, shifts]) => {
                const driverInfo = shifts[0].driver;
                const totalRevenue =
                    shifts.reduce((sum, shift) => sum + shift.finalTotalAmount, 0) / 100;
                const totalDistance =
                    shifts.reduce((sum, shift) => sum + shift.totalDistance, 0) / 1000;
                const hiredDistance =
                    shifts.reduce((sum, shift) => sum + shift.hiredDistance, 0) / 1000;
                const emptyDistance = totalDistance - hiredDistance;
                const emptyPercentage = (emptyDistance / totalDistance) * 100 || 0;
                const revenuePerKm = totalRevenue / totalDistance || 0;

                return {
                    id: driverNumber,
                    name: driverInfo.name,
                    revenue: totalRevenue,
                    totalDistance,
                    hiredDistance,
                    emptyDistance,
                    emptyPercentage,
                    revenuePerKm,
                    tripCount: shifts.reduce((sum, shift) => sum + shift.trips.length, 0),
                    shifts: shifts.length,
                };
            });
        },

        processCarPerformanceData() {
            // Similar calculation but for cars
            const carStats = Object.groupBy(this.shifts, shift => shift.licenseNumber);

            return Object.entries(carStats).map(([licenseNumber, shifts]) => {
                const totalRevenue =
                    shifts.reduce((sum, shift) => sum + shift.finalTotalAmount, 0) / 100;
                const totalDistance =
                    shifts.reduce((sum, shift) => sum + shift.totalDistance, 0) / 1000;
                const hiredDistance =
                    shifts.reduce((sum, shift) => sum + shift.hiredDistance, 0) / 1000;
                const emptyDistance = totalDistance - hiredDistance;
                const emptyPercentage = (emptyDistance / totalDistance) * 100 || 0;
                const revenuePerKm = totalRevenue / totalDistance || 0;

                return {
                    id: licenseNumber,
                    name: licenseNumber,
                    revenue: totalRevenue,
                    totalDistance,
                    hiredDistance,
                    emptyDistance,
                    emptyPercentage,
                    revenuePerKm,
                    tripCount: shifts.reduce((sum, shift) => sum + shift.trips.length, 0),
                    shifts: shifts.length,
                };
            });
        },
    },

    mounted() {
        this.handleQuery();
    },
};
</script>

<style lang="scss">
@import '../Overview/components/OverviewCard.scss';

.Analytics {
    @extend %contentWrapper;
    @extend %page;

    @media (max-width: breakpoint(tabletPortrait)) {
        height: calc(100% - 90px);
    }

    .toolbar-controls,
    .toolbar-actions {
        display: flex;
        align-items: center;
        gap: 12px;
    }
}
</style>
