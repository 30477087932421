<template>
    <div class="activity-heatmap">
        <div class="heatmap-header">
            <h3>Aktivitäts-Heatmap</h3>
            <div class="metric-toggle">
                <Button
                    v-for="metric in metrics"
                    :key="metric.id"
                    :class="['metric-button', selectedMetric === metric.id ? 'active' : '']"
                    size="small"
                    @onClick="() => setMetric(metric.id)"
                >
                    {{ metric.label }}
                </Button>
            </div>
        </div>

        <div class="heatmap-container" ref="heatmapContainer">
            <div class="chart-loader" v-if="loading">
                <i class="ri-loader-4-line spin"></i>
                <span>Lade Daten...</span>
            </div>
        </div>

        <div class="heatmap-insights">
            <div class="insight-card" v-for="(insight, index) in insights" :key="index">
                <div class="insight-icon">
                    <i :class="insight.icon"></i>
                </div>
                <div class="insight-content">
                    <h4>{{ insight.title }}</h4>
                    <p>{{ insight.description }}</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import * as d3 from 'd3';
import { format, getHours, getDay } from 'date-fns';
import { de } from 'date-fns/locale';
import Button from '@/components/widgets/Button';
import { round2d } from '@/lib/helper';

export default {
    name: 'ActivityHeatmap',
    components: {
        Button,
    },
    props: {
        shifts: {
            type: Array,
            required: true,
        },
        startAt: {
            type: Date,
            required: true,
        },
        endAt: {
            type: Date,
            required: true,
        },
    },
    data() {
        return {
            metrics: [
                { id: 'trips', label: 'Fahrten' },
                { id: 'revenue', label: 'Umsatz' },
                { id: 'emptyRate', label: 'Leerquote' },
            ],
            selectedMetric: 'trips',
            heatmap: null,
            heatmapData: [],
            insights: [],
            loading: true,
            days: ['Montag', 'Dienstag', 'Mittwoch', 'Donnerstag', 'Freitag', 'Samstag', 'Sonntag'],
        };
    },
    watch: {
        shifts() {
            this.loading = true;
            this.processHeatmapData();
            this.updateHeatmap();
        },
        selectedMetric() {
            this.loading = true;
            this.updateHeatmap();
        },
    },
    methods: {
        setMetric(metricId) {
            this.selectedMetric = metricId;
        },

        processHeatmapData() {
            // Initialize data structure for day/hour combinations
            const hours = Array.from({ length: 24 }, (_, i) => i);

            // Create grid with all day/hour combinations
            this.heatmapData = [];
            for (let day = 0; day < 7; day++) {
                for (let hour = 0; hour < 24; hour++) {
                    this.heatmapData.push({
                        day: this.days[day],
                        dayIndex: day,
                        hour,
                        formattedHour: `${hour}:00`,
                        trips: 0,
                        revenue: 0,
                        distance: 0,
                        emptyDistance: 0,
                        emptyRate: 0,
                    });
                }
            }

            // Fill in data from shifts
            this.shifts.forEach(shift => {
                shift.trips.forEach(trip => {
                    const startTime = new Date(trip.startAt);
                    const dayIndex = getDay(startTime) - 1; // 0 = Monday in our array, but getDay returns 0 = Sunday
                    const hour = getHours(startTime);

                    // Fix for Sunday which returns 0 in getDay()
                    const adjustedDayIndex = dayIndex < 0 ? 6 : dayIndex;

                    // Find the cell in our heatmap data
                    const cellIndex = adjustedDayIndex * 24 + hour;
                    if (cellIndex >= 0 && cellIndex < this.heatmapData.length) {
                        const cell = this.heatmapData[cellIndex];
                        cell.trips += 1;
                        cell.revenue += trip.amount / 100; // Convert to euros
                        cell.distance += trip.distance / 1000; // Convert to km

                        // Estimate empty distance (using overall proportion as approximation)
                        const shiftData = this.shifts.find(s => s.id === trip.shiftId);
                        if (shiftData) {
                            const emptyRatio =
                                (shiftData.totalDistance - shiftData.hiredDistance) /
                                    shiftData.totalDistance || 0;
                            cell.emptyDistance += (trip.distance / 1000) * emptyRatio;
                            cell.emptyRate = (cell.emptyDistance / cell.distance) * 100 || 0;
                        }
                    }
                });
            });

            // Generate insights
            this.generateInsights();
        },

        generateInsights() {
            try {
                // Find busiest hour
                const busiestHourCell = [...this.heatmapData].sort((a, b) => b.trips - a.trips)[0];

                // Find most profitable hour
                const mostProfitableHourCell = [...this.heatmapData].sort(
                    (a, b) => b.revenue - a.revenue,
                )[0];

                // Find lowest empty rate period
                const lowestEmptyRateCell = [...this.heatmapData]
                    .filter(cell => cell.trips > 0) // Only consider cells with activity
                    .sort((a, b) => a.emptyRate - b.emptyRate)[0];

                // Find daily patterns
                const dayTotals = {};
                this.days.forEach(day => {
                    dayTotals[day] = {
                        trips: 0,
                        revenue: 0,
                    };
                });

                this.heatmapData.forEach(cell => {
                    dayTotals[cell.day].trips += cell.trips;
                    dayTotals[cell.day].revenue += cell.revenue;
                });

                const busiestDay = Object.entries(dayTotals).sort(
                    (a, b) => b[1].trips - a[1].trips,
                )[0][0];

                const mostProfitableDay = Object.entries(dayTotals).sort(
                    (a, b) => b[1].revenue - a[1].revenue,
                )[0][0];

                // Generate insight cards
                this.insights = [
                    {
                        title: 'Höchste Nachfrage',
                        description: busiestHourCell
                            ? `${busiestHourCell.day} um ${busiestHourCell.formattedHour} mit ${busiestHourCell.trips} Fahrten`
                            : 'Keine Daten verfügbar',
                        icon: 'ri-time-line',
                    },
                    {
                        title: 'Profitabelste Zeit',
                        description: mostProfitableHourCell
                            ? `${mostProfitableHourCell.day} um ${
                                  mostProfitableHourCell.formattedHour
                              } mit ${round2d(mostProfitableHourCell.revenue).format()} €`
                            : 'Keine Daten verfügbar',
                        icon: 'ri-money-euro-circle-line',
                    },
                    {
                        title: 'Effizienteste Zeit',
                        description: lowestEmptyRateCell
                            ? `${lowestEmptyRateCell.day} um ${
                                  lowestEmptyRateCell.formattedHour
                              } mit nur ${round2d(
                                  lowestEmptyRateCell.emptyRate,
                              ).format()}% Leerfahrten`
                            : 'Keine Daten verfügbar',
                        icon: 'ri-gas-station-line',
                    },
                    {
                        title: 'Wochenübersicht',
                        description:
                            busiestDay && mostProfitableDay
                                ? `${busiestDay} ist am aktivsten, ${mostProfitableDay} ist am profitabelsten`
                                : 'Keine Daten verfügbar',
                        icon: 'ri-calendar-check-line',
                    },
                ];
            } catch (error) {
                console.error('Error generating insights:', error);
                // Provide default insights if there's an error
                this.insights = [
                    {
                        title: 'Höchste Nachfrage',
                        description: 'Keine ausreichenden Daten für Analyse',
                        icon: 'ri-time-line',
                    },
                    {
                        title: 'Profitabelste Zeit',
                        description: 'Keine ausreichenden Daten für Analyse',
                        icon: 'ri-money-euro-circle-line',
                    },
                    {
                        title: 'Effizienteste Zeit',
                        description: 'Keine ausreichenden Daten für Analyse',
                        icon: 'ri-gas-station-line',
                    },
                    {
                        title: 'Wochenübersicht',
                        description: 'Keine ausreichenden Daten für Analyse',
                        icon: 'ri-calendar-check-line',
                    },
                ];
            }
        },

        createHeatmap() {
            const container = this.$refs.heatmapContainer;
            if (!container) return;

            const margin = { top: 40, right: 30, bottom: 60, left: 80 };
            const width = container.clientWidth;
            const height = 400;
            const innerWidth = width - margin.left - margin.right;
            const innerHeight = height - margin.top - margin.bottom;

            // Clear previous chart
            d3.select(container)
                .selectAll('*')
                .remove();

            // Create SVG
            const svg = d3
                .select(container)
                .append('svg')
                .attr('width', width)
                .attr('height', height)
                .attr('class', 'heatmap-svg')
                .append('g')
                .attr('transform', `translate(${margin.left},${margin.top})`);

            // Define dimensions
            const cellWidth = Math.max(Math.min(innerWidth / 24, 30), 10);
            const cellHeight = Math.max(Math.min(innerHeight / 7, 30), 10);

            // Get data extent and color scale
            let extent, colorScale;

            if (this.selectedMetric === 'trips') {
                extent = [0, d3.max(this.heatmapData, d => d.trips) || 10];
                colorScale = d3.scaleSequential(d3.interpolateBlues).domain([0, extent[1]]);
            } else if (this.selectedMetric === 'revenue') {
                extent = [0, d3.max(this.heatmapData, d => d.revenue) || 100];
                colorScale = d3.scaleSequential(d3.interpolateGreens).domain([0, extent[1]]);
            } else {
                // emptyRate
                extent = [0, 100]; // Empty rate is always 0-100%
                colorScale = d3.scaleSequential(d3.interpolateReds).domain([0, 100]);
            }

            // Draw x-axis (hours)
            const xScale = d3
                .scaleBand()
                .domain(Array.from({ length: 24 }, (_, i) => i))
                .range([0, cellWidth * 24]);

            svg.append('g')
                .attr('class', 'x-axis')
                .attr('transform', `translate(0,${innerHeight})`)
                .call(
                    d3
                        .axisBottom(xScale)
                        .tickValues([0, 6, 12, 18, 23])
                        .tickFormat(d => `${d}:00`),
                )
                .selectAll('text')
                .style('font-size', '12px')
                .style('font-weight', '500');

            // Add x-axis label
            svg.append('text')
                .attr('class', 'x-axis-label')
                .attr('x', innerWidth / 2)
                .attr('y', innerHeight + 40)
                .attr('text-anchor', 'middle')
                .style('font-size', '12px')
                .style('font-weight', '600')
                .text('Stunde');

            // Draw y-axis (days)
            const yScale = d3
                .scaleBand()
                .domain(this.days)
                .range([0, cellHeight * 7]);

            svg.append('g')
                .attr('class', 'y-axis')
                .call(d3.axisLeft(yScale))
                .selectAll('text')
                .style('font-size', '12px')
                .style('font-weight', '500');

            // Add y-axis label
            svg.append('text')
                .attr('class', 'y-axis-label')
                .attr('transform', 'rotate(-90)')
                .attr('y', -60)
                .attr('x', -innerHeight / 2)
                .attr('text-anchor', 'middle')
                .style('font-size', '12px')
                .style('font-weight', '600')
                .text('Wochentag');

            // Draw heatmap cells
            svg.selectAll('.heatmap-cell')
                .data(this.heatmapData)
                .enter()
                .append('rect')
                .attr('class', 'heatmap-cell')
                .attr('x', d => xScale(d.hour))
                .attr('y', d => yScale(d.day))
                .attr('width', cellWidth)
                .attr('height', cellHeight)
                .attr('rx', 2)
                .attr('fill', d => {
                    if (this.selectedMetric === 'trips') {
                        return colorScale(d.trips);
                    } else if (this.selectedMetric === 'revenue') {
                        return colorScale(d.revenue);
                    } else {
                        return d.trips > 0 ? colorScale(d.emptyRate) : '#f1f5f9';
                    }
                })
                .attr('stroke', '#fff')
                .attr('stroke-width', 1)
                .on('mouseover', (event, d) => {
                    // Highlight cell
                    d3.select(event.currentTarget)
                        .transition()
                        .duration(100)
                        .attr('stroke', '#000')
                        .attr('stroke-width', 2);

                    // Show tooltip
                    const tooltip = svg
                        .append('g')
                        .attr('class', 'tooltip')
                        .attr(
                            'transform',
                            `translate(${xScale(d.hour) + cellWidth / 2}, ${yScale(d.day) - 10})`,
                        );

                    const tooltipBg = tooltip
                        .append('rect')
                        .attr('rx', 4)
                        .attr('fill', '#333')
                        .attr('opacity', 0.9);

                    const tooltipText = tooltip
                        .append('text')
                        .attr('text-anchor', 'middle')
                        .attr('fill', 'white')
                        .attr('dy', '0.32em')
                        .style('font-size', '12px');

                    // Get tooltip text based on metric
                    let tooltipContent;
                    if (this.selectedMetric === 'trips') {
                        tooltipContent = `${d.day} ${d.formattedHour}: ${d.trips} Fahrten`;
                    } else if (this.selectedMetric === 'revenue') {
                        tooltipContent = `${d.day} ${d.formattedHour}: ${round2d(
                            d.revenue,
                        ).format()} €`;
                    } else {
                        tooltipContent = `${d.day} ${d.formattedHour}: ${round2d(
                            d.emptyRate,
                        ).format()}% leer`;
                    }

                    tooltipText.text(tooltipContent);

                    const textBBox = tooltipText.node().getBBox();
                    tooltipBg
                        .attr('x', textBBox.x - 8)
                        .attr('y', textBBox.y - 4)
                        .attr('width', textBBox.width + 16)
                        .attr('height', textBBox.height + 8);
                })
                .on('mouseout', event => {
                    // Reset cell highlight
                    d3.select(event.currentTarget)
                        .transition()
                        .duration(100)
                        .attr('stroke', '#fff')
                        .attr('stroke-width', 1);

                    // Remove tooltip
                    svg.selectAll('.tooltip').remove();
                });

            // Add legend
            const legendWidth = 200;
            const legendHeight = 15;
            const legendX = innerWidth - legendWidth;
            const legendY = -30;

            // Legend gradient
            const defs = svg.append('defs');
            const legendGradientId = `legend-gradient-${this.selectedMetric}`;

            const legendGradient = defs
                .append('linearGradient')
                .attr('id', legendGradientId)
                .attr('x1', '0%')
                .attr('y1', '0%')
                .attr('x2', '100%')
                .attr('y2', '0%');

            // Add color stops based on the selected metric
            if (this.selectedMetric === 'trips') {
                legendGradient
                    .append('stop')
                    .attr('offset', '0%')
                    .attr('stop-color', colorScale(0));

                legendGradient
                    .append('stop')
                    .attr('offset', '100%')
                    .attr('stop-color', colorScale(extent[1]));
            } else if (this.selectedMetric === 'revenue') {
                legendGradient
                    .append('stop')
                    .attr('offset', '0%')
                    .attr('stop-color', colorScale(0));

                legendGradient
                    .append('stop')
                    .attr('offset', '100%')
                    .attr('stop-color', colorScale(extent[1]));
            } else {
                // emptyRate
                legendGradient
                    .append('stop')
                    .attr('offset', '0%')
                    .attr('stop-color', colorScale(0));

                legendGradient
                    .append('stop')
                    .attr('offset', '100%')
                    .attr('stop-color', colorScale(100));
            }

            // Draw legend rectangle
            svg.append('rect')
                .attr('x', legendX)
                .attr('y', legendY)
                .attr('width', legendWidth)
                .attr('height', legendHeight)
                .attr('fill', `url(#${legendGradientId})`)
                .attr('rx', 3);

            // Legend title
            svg.append('text')
                .attr('x', legendX + legendWidth / 2)
                .attr('y', legendY - 8)
                .attr('text-anchor', 'middle')
                .style('font-size', '12px')
                .style('font-weight', '600')
                .text(this.getLegendTitle());

            // Legend min value
            svg.append('text')
                .attr('x', legendX)
                .attr('y', legendY + legendHeight + 15)
                .attr('text-anchor', 'start')
                .style('font-size', '11px')
                .text(this.getLegendMinText());

            // Legend max value
            svg.append('text')
                .attr('x', legendX + legendWidth)
                .attr('y', legendY + legendHeight + 15)
                .attr('text-anchor', 'end')
                .style('font-size', '11px')
                .text(this.getLegendMaxText(extent[1]));

            // Add chart title
            svg.append('text')
                .attr('class', 'chart-title')
                .attr('x', innerWidth / 2)
                .attr('y', -20)
                .attr('text-anchor', 'middle')
                .style('font-size', '14px')
                .style('font-weight', 'bold')
                .text(this.getChartTitle());

            // Store references for updates
            this.heatmap = {
                svg,
                xScale,
                yScale,
                colorScale,
                cellWidth,
                cellHeight,
                innerWidth,
                innerHeight,
            };

            setTimeout(() => {
                this.loading = false;
            }, 500);
        },

        updateHeatmap() {
            if (this.heatmap) {
                // Get new color scale based on selected metric
                let extent, colorScale;

                if (this.selectedMetric === 'trips') {
                    extent = [0, d3.max(this.heatmapData, d => d.trips) || 10];
                    colorScale = d3.scaleSequential(d3.interpolateBlues).domain([0, extent[1]]);
                } else if (this.selectedMetric === 'revenue') {
                    extent = [0, d3.max(this.heatmapData, d => d.revenue) || 100];
                    colorScale = d3.scaleSequential(d3.interpolateGreens).domain([0, extent[1]]);
                } else {
                    // emptyRate
                    extent = [0, 100]; // Empty rate is always 0-100%
                    colorScale = d3.scaleSequential(d3.interpolateReds).domain([0, 100]);
                }

                // Update cells with new colors
                const { svg, cellWidth, cellHeight, xScale, yScale } = this.heatmap;

                svg.selectAll('.heatmap-cell')
                    .data(this.heatmapData)
                    .transition()
                    .duration(500)
                    .attr('fill', d => {
                        if (this.selectedMetric === 'trips') {
                            return colorScale(d.trips);
                        } else if (this.selectedMetric === 'revenue') {
                            return colorScale(d.revenue);
                        } else {
                            return d.trips > 0 ? colorScale(d.emptyRate) : '#f1f5f9';
                        }
                    });

                // Update legend
                svg.select('.chart-title').text(this.getChartTitle());

                // Update legend gradient
                const legendGradientId = `legend-gradient-${this.selectedMetric}`;
                const defs = svg.select('defs');

                // Remove old gradient
                defs.selectAll('linearGradient').remove();

                // Create new gradient
                const legendGradient = defs
                    .append('linearGradient')
                    .attr('id', legendGradientId)
                    .attr('x1', '0%')
                    .attr('y1', '0%')
                    .attr('x2', '100%')
                    .attr('y2', '0%');

                // Add color stops based on the selected metric
                if (this.selectedMetric === 'trips') {
                    legendGradient
                        .append('stop')
                        .attr('offset', '0%')
                        .attr('stop-color', colorScale(0));

                    legendGradient
                        .append('stop')
                        .attr('offset', '100%')
                        .attr('stop-color', colorScale(extent[1]));
                } else if (this.selectedMetric === 'revenue') {
                    legendGradient
                        .append('stop')
                        .attr('offset', '0%')
                        .attr('stop-color', colorScale(0));

                    legendGradient
                        .append('stop')
                        .attr('offset', '100%')
                        .attr('stop-color', colorScale(extent[1]));
                } else {
                    // emptyRate
                    legendGradient
                        .append('stop')
                        .attr('offset', '0%')
                        .attr('stop-color', colorScale(0));

                    legendGradient
                        .append('stop')
                        .attr('offset', '100%')
                        .attr('stop-color', colorScale(100));
                }

                // Update legend rectangle
                svg.select('rect[fill^="url(#legend-gradient"]').attr(
                    'fill',
                    `url(#${legendGradientId})`,
                );

                // Update legend title and values
                const legendTexts = svg.selectAll('text').filter(function() {
                    return (
                        d3.select(this).attr('text-anchor') === 'middle' ||
                        d3.select(this).attr('text-anchor') === 'start' ||
                        d3.select(this).attr('text-anchor') === 'end'
                    );
                });

                legendTexts.each(function(d, i) {
                    const element = d3.select(this);
                    const anchor = element.attr('text-anchor');

                    // Title (middle)
                    if (anchor === 'middle' && i === 0) {
                        element.text(self.getLegendTitle());
                    }
                    // Min value (start)
                    else if (anchor === 'start') {
                        element.text(self.getLegendMinText());
                    }
                    // Max value (end)
                    else if (anchor === 'end') {
                        element.text(self.getLegendMaxText(extent[1]));
                    }
                });

                setTimeout(() => {
                    this.loading = false;
                }, 500);
            } else {
                this.createHeatmap();
            }
        },

        getLegendTitle() {
            if (this.selectedMetric === 'trips') {
                return 'Anzahl der Fahrten';
            } else if (this.selectedMetric === 'revenue') {
                return 'Umsatz (€)';
            } else {
                return 'Leerquote (%)';
            }
        },

        getLegendMinText() {
            if (this.selectedMetric === 'trips') {
                return '0 Fahrten';
            } else if (this.selectedMetric === 'revenue') {
                return '0 €';
            } else {
                return '0% (optimal)';
            }
        },

        getLegendMaxText(maxValue) {
            if (this.selectedMetric === 'trips') {
                return `${Math.round(maxValue)} Fahrten`;
            } else if (this.selectedMetric === 'revenue') {
                return `${round2d(maxValue).format()} €`;
            } else {
                return '100% (kritisch)';
            }
        },

        getChartTitle() {
            if (this.selectedMetric === 'trips') {
                return 'Verteilung der Fahrten nach Wochentag und Uhrzeit';
            } else if (this.selectedMetric === 'revenue') {
                return 'Verteilung des Umsatzes nach Wochentag und Uhrzeit';
            } else {
                return 'Verteilung der Leerquote nach Wochentag und Uhrzeit';
            }
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.processHeatmapData();
            this.createHeatmap();

            // Add resize handler
            this.resizeHandler = () => {
                this.loading = true;
                this.heatmap = null;
                this.createHeatmap();
            };

            window.addEventListener('resize', this.resizeHandler);
        });
    },
    beforeDestroy() {
        if (this.resizeHandler) {
            window.removeEventListener('resize', this.resizeHandler);
        }
    },
};
</script>

<style lang="scss">
.activity-heatmap {
    background-color: white;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    position: relative;
    overflow: hidden;

    .heatmap-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
        }

        h3 {
            font-size: 1.2rem;
            font-weight: 600;
            margin: 0;
            color: #1e293b;
        }

        .metric-toggle {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;

            .metric-button {
                background-color: #f1f5f9;
                color: #64748b;
                border: none;
                font-size: 0.8rem;
                padding: 6px 12px;
                border-radius: 20px;
                font-weight: 500;
                transition: all 0.2s ease;

                &:hover {
                    background-color: #e2e8f0;
                }

                &.active {
                    background-color: #0ea5e9;
                    color: white;
                }
            }
        }
    }

    .heatmap-container {
        background-color: #f8fafc;
        border-radius: 8px;
        min-height: 400px;
        margin-bottom: 24px;
        position: relative;

        .chart-loader {
            position: absolute;
            inset: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(2px);
            z-index: 5;

            i {
                font-size: 2rem;
                color: #0ea5e9;
                margin-bottom: 10px;
            }

            span {
                font-size: 0.9rem;
                color: #64748b;
                font-weight: 500;
            }

            .spin {
                animation: spin 1.5s linear infinite;
            }

            @keyframes spin {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }
        }

        .heatmap-svg {
            .x-axis,
            .y-axis {
                path.domain {
                    stroke: #cbd5e1;
                }

                .tick line {
                    stroke: #cbd5e1;
                }

                text {
                    fill: #64748b;
                }
            }

            .heatmap-cell {
                transition: fill 0.5s ease;

                &:hover {
                    cursor: pointer;
                }
            }
        }
    }

    .heatmap-insights {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;

        @media (max-width: 1100px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 580px) {
            grid-template-columns: 1fr;
        }

        .insight-card {
            background-color: #f8fafc;
            border-radius: 12px;
            padding: 16px;
            display: flex;
            align-items: flex-start;
            gap: 12px;
            border-left: 3px solid #0ea5e9;

            .insight-icon {
                width: 40px;
                height: 40px;
                border-radius: 10px;
                background-color: rgba(14, 165, 233, 0.1);
                display: flex;
                align-items: center;
                justify-content: center;
                color: #0ea5e9;
                font-size: 1.2rem;
                flex-shrink: 0;
            }

            .insight-content {
                flex: 1;

                h4 {
                    margin: 0 0 8px;
                    font-size: 0.95rem;
                    color: #0f172a;
                    font-weight: 600;
                }

                p {
                    margin: 0;
                    font-size: 0.85rem;
                    color: #64748b;
                    line-height: 1.4;
                }
            }

            &:nth-child(2) {
                border-left-color: #22c55e;

                .insight-icon {
                    background-color: rgba(34, 197, 94, 0.1);
                    color: #22c55e;
                }
            }

            &:nth-child(3) {
                border-left-color: #f59e0b;

                .insight-icon {
                    background-color: rgba(245, 158, 11, 0.1);
                    color: #f59e0b;
                }
            }

            &:nth-child(4) {
                border-left-color: #8b5cf6;

                .insight-icon {
                    background-color: rgba(139, 92, 246, 0.1);
                    color: #8b5cf6;
                }
            }
        }
    }
}
</style>
