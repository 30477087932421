<template>
    <div class="revenue-trends">
        <div class="chart-header">
            <h3>Umsatz- & Nutzungstrends</h3>
            <div class="chart-controls">
                <Button
                    v-for="period in timeRangePeriods"
                    :key="period.value"
                    :class="['period-button', selectedPeriod === period.value ? 'active' : '']"
                    size="small"
                    @onClick="() => setTimeRange(period.value)"
                >
                    {{ period.label }}
                </Button>
            </div>
        </div>

        <div class="trend-summary">
            <div class="trend-card">
                <div class="trend-icon">
                    <i class="ri-currency-line"></i>
                </div>
                <div class="trend-data">
                    <div class="trend-label">Durchschn. Tagesumsatz</div>
                    <div class="trend-value">{{ formatCurrency(avgDailyRevenue) }}</div>
                    <div class="trend-change" :class="revenueTrend.class">
                        {{ revenueTrend.text }}
                        <i
                            class="ri-arrow-up-line trend-icon"
                            :class="{ 'ri-arrow-down-line': revenueTrend.class === 'negative' }"
                        ></i>
                    </div>
                </div>
            </div>

            <div class="trend-card">
                <div class="trend-icon">
                    <i class="ri-bar-chart-grouped-line"></i>
                </div>
                <div class="trend-data">
                    <div class="trend-label">Geschäftigster Tag</div>
                    <div class="trend-value">{{ busiestDay.day }}</div>
                    <div class="trend-sub">{{ formatCurrency(busiestDay.revenue) }}</div>
                </div>
            </div>

            <div class="trend-card">
                <div class="trend-icon">
                    <i class="ri-bar-chart-line"></i>
                </div>
                <div class="trend-data">
                    <div class="trend-label">Ruhigster Tag</div>
                    <div class="trend-value">{{ slowestDay.day }}</div>
                    <div class="trend-sub">{{ formatCurrency(slowestDay.revenue) }}</div>
                </div>
            </div>

            <div class="trend-card">
                <div class="trend-icon">
                    <i class="ri-line-chart-line"></i>
                </div>
                <div class="trend-data">
                    <div class="trend-label">Wachstum</div>
                    <div class="trend-value">{{ growthRate }}%</div>
                    <div class="trend-sub">gegenüber Vorperiode</div>
                </div>
            </div>
        </div>

        <div class="chart-container">
            <div class="chart-loader" v-if="loading">
                <i class="ri-loader-4-line spin"></i>
                <span>Lade Daten...</span>
            </div>
            <ChartV2
                v-else
                title="Täglicher Umsatz"
                type="bar"
                :values="revenueChartData"
                :halfHeight="true"
            />
        </div>

        <div class="chart-container">
            <div class="chart-loader" v-if="loading">
                <i class="ri-loader-4-line spin"></i>
                <span>Lade Daten...</span>
            </div>
            <ChartV2
                v-else
                title="Leerkilometer-Quote"
                type="bar"
                :values="utilizationChartData"
                color="blue"
                :halfHeight="true"
            />
        </div>
    </div>
</template>

<script>
import {
    format,
    subDays,
    subMonths,
    differenceInDays,
    parseISO,
    isWeekend,
    formatISO,
} from 'date-fns';
import { de } from 'date-fns/locale';
import Button from '@/components/widgets/Button';
import ChartV2 from '@/components/widgets/ChartV2.vue';
import { round2d } from '@/lib/helper';

export default {
    name: 'RevenueTrends',
    components: {
        Button,
        ChartV2,
    },
    props: {
        shifts: {
            type: Array,
            required: true,
        },
        startAt: {
            type: Date,
            required: true,
        },
        endAt: {
            type: Date,
            required: true,
        },
    },
    data() {
        return {
            timeRangePeriods: [
                { value: '7d', label: '7 Tage' },
                { value: '30d', label: '30 Tage' },
                { value: '90d', label: '90 Tage' },
            ],
            selectedPeriod: '30d',
            loading: true,
            revenueChartData: [],
            utilizationChartData: [],
        };
    },
    computed: {
        // Process data for daily revenues
        dailyData() {
            const endDate = new Date(this.endAt);
            let startDate;

            switch (this.selectedPeriod) {
                case '7d':
                    startDate = subDays(endDate, 7);
                    break;
                case '90d':
                    startDate = subDays(endDate, 90);
                    break;
                default:
                    // 30d
                    startDate = subDays(endDate, 30);
            }

            // Create array of all dates in range
            const dayCount = differenceInDays(endDate, startDate) + 1;
            const allDates = Array.from({ length: dayCount }, (_, i) => {
                const date = new Date(startDate);
                date.setDate(date.getDate() + i);
                return date;
            });

            // Calculate revenue for each day
            return allDates.map(date => {
                const dayShifts = this.shifts.filter(shift => {
                    const shiftDate = new Date(shift.startAt);
                    return shiftDate.toDateString() === date.toDateString();
                });

                const totalRevenue =
                    dayShifts.reduce((sum, shift) => sum + shift.finalTotalAmount, 0) / 100;
                const totalDistance =
                    dayShifts.reduce((sum, shift) => sum + shift.totalDistance, 0) / 1000;
                const hiredDistance =
                    dayShifts.reduce((sum, shift) => sum + shift.hiredDistance, 0) / 1000;
                const emptyDistance = totalDistance - hiredDistance;
                const emptyPercentage = (emptyDistance / totalDistance) * 100 || 0;

                return {
                    date,
                    formattedDate: format(date, 'dd.MM', { locale: de }),
                    dayName: format(date, 'EEE', { locale: de }),
                    revenue: totalRevenue,
                    distance: totalDistance,
                    hiredDistance,
                    emptyDistance,
                    emptyPercentage,
                    isWeekend: isWeekend(date),
                };
            });
        },

        avgDailyRevenue() {
            if (!this.dailyData.length) return 0;
            return (
                this.dailyData.reduce((sum, day) => sum + day.revenue, 0) / this.dailyData.length
            );
        },

        busiestDay() {
            if (!this.dailyData.length) return { day: 'N/A', revenue: 0 };
            const busiest = this.dailyData.reduce(
                (max, day) => (day.revenue > max.revenue ? day : max),
                { revenue: 0 },
            );
            return {
                day: format(busiest.date, 'EEEE, dd.MM', { locale: de }),
                revenue: busiest.revenue,
            };
        },

        slowestDay() {
            if (!this.dailyData.length) return { day: 'N/A', revenue: 0 };
            // Find non-zero minimum
            const days = this.dailyData.filter(day => day.revenue > 0);
            if (!days.length) return { day: 'N/A', revenue: 0 };

            const slowest = days.reduce((min, day) => (day.revenue < min.revenue ? day : min), {
                revenue: Number.MAX_VALUE,
            });
            return {
                day: format(slowest.date, 'EEEE, dd.MM', { locale: de }),
                revenue: slowest.revenue,
            };
        },

        revenueTrend() {
            // Calculate trend from first half to second half of period
            if (this.dailyData.length < 2) return { text: 'Keine Daten', class: 'neutral' };

            const midpoint = Math.floor(this.dailyData.length / 2);
            const firstHalfAvg =
                this.dailyData.slice(0, midpoint).reduce((sum, day) => sum + day.revenue, 0) /
                midpoint;
            const secondHalfAvg =
                this.dailyData.slice(midpoint).reduce((sum, day) => sum + day.revenue, 0) /
                (this.dailyData.length - midpoint);

            const percentChange = ((secondHalfAvg - firstHalfAvg) / firstHalfAvg) * 100;

            return {
                text: `${Math.abs(round2d(percentChange).value).toFixed(1)}% ${
                    percentChange >= 0 ? 'höher' : 'niedriger'
                }`,
                class: percentChange >= 0 ? 'positive' : 'negative',
            };
        },

        growthRate() {
            // Compare to previous similar period
            if (this.dailyData.length === 0) return 0;

            // Simulate previous period data (in a real app, you'd fetch historical data)
            // This is a placeholder with random trend for demonstration
            const trend = (Math.random() > 0.5 ? 1 : -1) * (Math.random() * 15 + 2);
            return round2d(trend).value.toFixed(1);
        },
    },
    watch: {
        selectedPeriod() {
            this.loading = true;
            this.prepareChartData();
        },
        shifts() {
            this.loading = true;
            this.prepareChartData();
        },
    },
    methods: {
        setTimeRange(period) {
            this.selectedPeriod = period;
        },

        formatCurrency(amount) {
            return round2d(amount).format() + ' €';
        },

        prepareChartData() {
            // Prepare data for revenue chart (ChartV2 format)
            this.revenueChartData = this.dailyData.map(day => {
                return {
                    date: day.formattedDate, // For x-axis label
                    formattedDate: `${day.dayName}, ${day.formattedDate}`, // For tooltips
                    value: day.revenue, // The primary value for the bar
                    formattedValue: this.formatCurrency(day.revenue), // For tooltips
                    rawDate: formatISO(day.date), // Date string for sorting
                    isWeekend: day.isWeekend, // Additional info for styling
                    trips: 0, // Required by ChartV2 but not used here
                };
            });

            // Prepare data for utilization chart (ChartV2 format)
            this.utilizationChartData = this.dailyData.map(day => {
                return {
                    date: day.formattedDate, // For x-axis label
                    formattedDate: `${day.dayName}, ${day.formattedDate}`, // For tooltips
                    value: day.emptyPercentage, // The primary value for the bars
                    formattedValue: round2d(day.emptyPercentage).format() + '%', // For tooltips
                    rawDate: formatISO(day.date), // Date string for sorting
                    isWeekend: day.isWeekend, // Additional info for styling
                    color: this.getEmptyPercentageColor(day.emptyPercentage),
                    trips: 0, // Required by ChartV2 but not used here
                };
            });

            // Simulate data loading
            setTimeout(() => {
                this.loading = false;
            }, 500);
        },

        getEmptyPercentageColor(percentage) {
            if (percentage < 40) return '#22c55e'; // green
            if (percentage < 60) return '#eab308'; // yellow
            return '#ef4444'; // red
        },
    },
    mounted() {
        this.$nextTick(() => {
            this.prepareChartData();
        });
    },
};
</script>

<style lang="scss">
.revenue-trends {
    .chart-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        @media (max-width: 768px) {
            flex-direction: column;
            align-items: flex-start;
            gap: 15px;
        }

        h3 {
            margin: 0;
            font-size: 1.2rem;
            font-weight: 600;
            color: #1e293b;
        }

        .chart-controls {
            display: flex;
            gap: 8px;
            flex-wrap: wrap;

            .period-button {
                background-color: #f1f5f9;
                color: #64748b;
                border: none;
                font-size: 0.8rem;
                padding: 6px 12px;
                border-radius: 20px;
                font-weight: 500;
                transition: all 0.2s ease;

                &:hover {
                    background-color: #e2e8f0;
                }

                &.active {
                    background-color: #0ea5e9;
                    color: white;
                }
            }
        }
    }

    .trend-summary {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        gap: 16px;
        margin-bottom: 24px;

        @media (max-width: 1100px) {
            grid-template-columns: repeat(2, 1fr);
        }

        @media (max-width: 580px) {
            grid-template-columns: 1fr;
        }

        .trend-card {
            background-color: white;
            border-radius: 12px;
            padding: 16px;
            box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
            display: flex;
            align-items: center;
            gap: 16px;

            .trend-icon {
                flex-shrink: 0;
                width: 48px;
                height: 48px;
                border-radius: 12px;
                background: linear-gradient(135deg, #0ea5e9, #38bdf8);
                display: flex;
                align-items: center;
                justify-content: center;
                color: white;
                font-size: 1.5rem;
                box-shadow: 0 4px 6px rgba(14, 165, 233, 0.2);
            }

            .trend-data {
                flex: 1;

                .trend-label {
                    font-size: 0.8rem;
                    color: #64748b;
                    margin-bottom: 4px;
                }

                .trend-value {
                    font-size: 1.2rem;
                    font-weight: 700;
                    color: #0f172a;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }

                .trend-sub {
                    font-size: 0.85rem;
                    color: #64748b;
                    margin-top: 4px;
                }

                .trend-change {
                    display: flex;
                    align-items: center;
                    gap: 4px;
                    font-size: 0.85rem;
                    font-weight: 600;
                    margin-top: 4px;

                    &.positive {
                        color: #16a34a;
                    }

                    &.negative {
                        color: #dc2626;
                    }

                    &.neutral {
                        color: #64748b;
                    }
                }
            }
        }
    }

    .chart-container {
        background-color: white;
        border-radius: 12px;
        box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
        padding: 0;
        margin-bottom: 24px;
        position: relative;
        overflow: hidden;

        .chart-loader {
            position: absolute;
            inset: 0;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            background-color: rgba(255, 255, 255, 0.8);
            backdrop-filter: blur(2px);
            z-index: 5;
            height: 250px;

            i {
                font-size: 2rem;
                color: #0ea5e9;
                margin-bottom: 10px;
            }

            span {
                font-size: 0.9rem;
                color: #64748b;
                font-weight: 500;
            }

            .spin {
                animation: spin 1.5s linear infinite;
            }

            @keyframes spin {
                from {
                    transform: rotate(0deg);
                }
                to {
                    transform: rotate(360deg);
                }
            }
        }
    }
}
</style>
