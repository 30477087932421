<template>
    <div class="business-insights">
        <div class="insights-header">
            <h3>Geschäftsanalyse & Empfehlungen</h3>
        </div>

        <div class="insights-grid">
            <!-- Time analysis chart at top -->
            <div class="time-analysis-section">
                <div v-if="isChartLoading" class="chart-loader">
                    <i class="ri-loader-4-line spin"></i>
                    <span>Lade Daten...</span>
                </div>
                <!-- Using ChartV2 component with correct data format -->
                <ChartV2
                    v-else
                    title="Stündliche Aktivität"
                    type="bar"
                    :values="chartValues"
                    :halfHeight="true"
                >
                </ChartV2>
            </div>

            <!-- Integrated optimization and recommendations section -->
            <div class="integrated-insights-section">
                <h4>Optimierungspotential & Empfehlungen</h4>
                <div class="insights-list">
                    <Card v-for="(rec, index) in recommendations" :key="index" class="insight-card">
                        <div class="insight-header">
                            <div class="insight-icon" :class="rec.priorityClass">
                                <i :class="rec.icon || 'ri-lightbulb-line'"></i>
                            </div>
                            <div class="insight-title-area">
                                <div class="priority-badge" :class="rec.priorityClass">
                                    {{ rec.priority }}
                                </div>
                                <div class="insight-title">{{ rec.title }}</div>
                            </div>
                        </div>

                        <div class="insight-content">
                            <p>{{ rec.description }}</p>

                            <div class="metrics-row">
                                <div class="potential-section">
                                    <div class="potential-bar">
                                        <div
                                            class="potential-value"
                                            :style="{ width: rec.potential + '%' }"
                                            :class="rec.priorityClass"
                                        ></div>
                                    </div>
                                    <div class="potential-label">
                                        <span
                                            >{{
                                                rec.potentialLabel || 'Optimierungspotential'
                                            }}:</span
                                        >
                                        <strong>{{ rec.potential }}%</strong>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Card>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { differenceInDays, parseISO, format } from 'date-fns';
import { round2d } from '@/lib/helper';
import ChartV2 from '@/components/widgets/ChartV2.vue';
import Card from '@/components/Card.vue';

export default {
    name: 'BusinessInsights',
    components: {
        ChartV2,
        Card,
    },
    props: {
        shifts: {
            type: Array,
            required: true,
        },
        startAt: {
            type: Date,
            required: true,
        },
        endAt: {
            type: Date,
            required: true,
        },
        option: {
            type: Object,
            required: true,
        },
        summaryMetrics: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            opportunities: [],
            recommendations: [],
            isChartLoading: true,
            chartValues: [],
        };
    },
    computed: {
        totalRevenue() {
            return this.shifts.reduce((sum, shift) => sum + shift.finalTotalAmount, 0) / 100;
        },

        totalDistance() {
            return this.shifts.reduce((sum, shift) => sum + shift.totalDistance, 0) / 1000;
        },

        totalHiredDistance() {
            return this.shifts.reduce((sum, shift) => sum + shift.hiredDistance, 0) / 1000;
        },

        emptyDistance() {
            return this.totalDistance - this.totalHiredDistance;
        },

        emptyPercentage() {
            return (this.emptyDistance / this.totalDistance) * 100 || 0;
        },

        revenuePerKm() {
            return this.totalRevenue / this.totalDistance || 0;
        },

        totalShiftHours() {
            return this.shifts.reduce((sum, shift) => {
                const startTime = new Date(shift.startAt).getTime();
                const endTime = new Date(shift.endAt).getTime();
                return sum + (endTime - startTime) / (1000 * 60 * 60);
            }, 0);
        },

        revenuePerHour() {
            return this.totalRevenue / this.totalShiftHours || 0;
        },

        driversData() {
            const driversById = {};

            this.shifts.forEach(shift => {
                const driverId = shift.driver.driverNumber;
                if (!driversById[driverId]) {
                    driversById[driverId] = {
                        id: driverId,
                        name: shift.driver.name,
                        shifts: [],
                        totalRevenue: 0,
                        totalDistance: 0,
                        hiredDistance: 0,
                        emptyDistance: 0,
                        emptyPercentage: 0,
                        revenuePerKm: 0,
                        tripCount: 0,
                        hours: 0,
                    };
                }

                driversById[driverId].shifts.push(shift);
                driversById[driverId].totalRevenue += shift.finalTotalAmount / 100;
                driversById[driverId].totalDistance += shift.totalDistance / 1000;
                driversById[driverId].hiredDistance += shift.hiredDistance / 1000;
                driversById[driverId].tripCount += shift.trips.length;

                // Calculate hours
                const startTime = new Date(shift.startAt).getTime();
                const endTime = new Date(shift.endAt).getTime();
                driversById[driverId].hours += (endTime - startTime) / (1000 * 60 * 60);
            });

            // Calculate derived metrics
            Object.values(driversById).forEach(driver => {
                driver.emptyDistance = driver.totalDistance - driver.hiredDistance;
                driver.emptyPercentage = (driver.emptyDistance / driver.totalDistance) * 100 || 0;
                driver.revenuePerKm = driver.totalRevenue / driver.totalDistance || 0;
                driver.revenuePerHour = driver.totalRevenue / driver.hours || 0;
            });

            return Object.values(driversById);
        },

        carsData() {
            const carsById = {};

            this.shifts.forEach(shift => {
                const carId = shift.licenseNumber;
                if (!carsById[carId]) {
                    carsById[carId] = {
                        id: carId,
                        licenseNumber: carId,
                        shifts: [],
                        totalRevenue: 0,
                        totalDistance: 0,
                        hiredDistance: 0,
                        emptyDistance: 0,
                        emptyPercentage: 0,
                        revenuePerKm: 0,
                        tripCount: 0,
                        hours: 0,
                    };
                }

                carsById[carId].shifts.push(shift);
                carsById[carId].totalRevenue += shift.finalTotalAmount / 100;
                carsById[carId].totalDistance += shift.totalDistance / 1000;
                carsById[carId].hiredDistance += shift.hiredDistance / 1000;
                carsById[carId].tripCount += shift.trips.length;

                // Calculate hours
                const startTime = new Date(shift.startAt).getTime();
                const endTime = new Date(shift.endAt).getTime();
                carsById[carId].hours += (endTime - startTime) / (1000 * 60 * 60);
            });

            // Calculate derived metrics
            Object.values(carsById).forEach(car => {
                car.emptyDistance = car.totalDistance - car.hiredDistance;
                car.emptyPercentage = (car.emptyDistance / car.totalDistance) * 100 || 0;
                car.revenuePerKm = car.totalRevenue / car.totalDistance || 0;
                car.revenuePerHour = car.totalRevenue / car.hours || 0;
            });

            return Object.values(carsById);
        },

        entityData() {
            return this.option.id === 'driver' ? this.driversData : this.carsData;
        },

        hourlyData() {
            const hours = Array.from({ length: 24 }, (_, i) => ({
                hour: i,
                revenue: 0,
                trips: 0,
                distance: 0,
                emptyPercentage: 0,
            }));

            this.shifts.forEach(shift => {
                shift.trips.forEach(trip => {
                    try {
                        const hour = new Date(trip.startAt).getHours();
                        // Use totalAmount instead of amount (which doesn't exist in the data)
                        const amount = typeof trip.totalAmount === 'number' ? trip.totalAmount : 0;
                        hours[hour].revenue += amount / 100;
                        hours[hour].trips += 1;
                        hours[hour].distance += (trip.totalDistance || 0) / 1000;
                    } catch (error) {
                        console.error('Error processing trip data:', error);
                    }
                });
            });

            return hours;
        },
    },
    watch: {
        shifts() {
            this.analyzeData();
        },
        option() {
            this.analyzeData();
        },
    },
    methods: {
        analyzeData() {
            this.isChartLoading = true;
            this.prepareChartData();
            this.findOpportunities();
            this.generateRecommendations();
            this.isChartLoading = false;
        },

        prepareChartData() {
            // Use simpler date format matching Revenue/index.vue exactly
            this.chartValues = this.hourlyData.map((hour, index) => {
                const hourString = hour.hour < 10 ? `0${hour.hour}` : `${hour.hour}`;
                const today = new Date();
                const dateStr = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
                    2,
                    '0',
                )}-${String(today.getDate()).padStart(2, '0')}T${hourString}:00:00`;

                // Ensure revenue is a valid number
                const revenue = isNaN(hour.revenue) ? 0 : hour.revenue;

                return {
                    date: `${hourString}:00`, // For x-axis label
                    value: revenue, // The primary value for the bar
                    formattedValue: `${round2d(revenue).format()} €`, // For tooltips
                    formattedDate: `${hourString}:00 h`, // For display and tooltips
                    rawDate: dateStr, // String date format
                    trips: hour.trips,
                };
            });

            // Log the first entry with a non-zero value for debugging
            const nonZeroValue = this.chartValues.find(value => value.value > 0);
            if (nonZeroValue) {
                console.log('First non-zero chart value:', nonZeroValue);
            } else {
                console.log('All chart values are zero or invalid');
            }

            // Ensure we have at least some data points
            if (this.chartValues.length === 0 || this.chartValues.every(v => v.value === 0)) {
                const sampleData = [];
                for (let i = 0; i < 24; i += 4) {
                    const hourString = i < 10 ? `0${i}` : `${i}`;
                    const today = new Date();
                    const dateStr = `${today.getFullYear()}-${String(today.getMonth() + 1).padStart(
                        2,
                        '0',
                    )}-${String(today.getDate()).padStart(2, '0')}T${hourString}:00:00`;

                    sampleData.push({
                        date: `${hourString}:00`,
                        value: i === 0 ? 0.01 : 0,
                        formattedValue: i === 0 ? '0,01 €' : '0,00 €',
                        formattedDate: `${format(today, 'dd.MM.yyyy')} ${hourString}:00`,
                        rawDate: dateStr,
                        trips: 0,
                    });
                }
                this.chartValues = sampleData;
            }
        },

        getMetricIcon(metric) {
            const iconMap = {
                revenue: 'ri-money-dollar-circle-line',
                distance: 'ri-route-line',
                efficiency: 'ri-line-chart-line',
                utilization: 'ri-gas-station-line',
                performance: 'ri-speed-line',
            };

            return iconMap[metric.id] || 'ri-bar-chart-line';
        },

        findOpportunities() {
            // Simplified to focus on the most important opportunities
            this.opportunities = [];

            // Add high empty rate opportunity if relevant
            if (this.emptyPercentage > 40) {
                this.opportunities.push({
                    title: 'Reduzierung der Leerkilometer',
                    icon: 'ri-route-line',
                    potential: Math.min(100, this.emptyPercentage),
                    potentialLabel: 'Hohes Potential',
                    priorityClass: this.emptyPercentage > 55 ? 'high' : 'medium',
                });
            }

            // Add high demand hours opportunity
            const hourlyDemand = this.hourlyData
                .map((hour, index) => ({
                    hour: index,
                    label: `${index}:00 - ${index + 1}:00`,
                    demand: hour.revenue,
                }))
                .sort((a, b) => b.demand - a.demand);

            const topDemandHours = hourlyDemand.slice(0, 3);

            if (topDemandHours.length > 0) {
                this.opportunities.push({
                    title: 'Nachfrage-Optimierung',
                    icon: 'ri-time-line',
                    potential: 75,
                    potentialLabel: 'Mittleres Potential',
                    priorityClass: 'medium',
                });
            }

            // Add entity optimization if relevant
            const highEmptyRateEntities = this.entityData
                .filter(entity => entity.emptyPercentage > 50)
                .sort((a, b) => b.emptyPercentage - a.emptyPercentage);

            if (highEmptyRateEntities.length > 0) {
                const entityType = this.option.id === 'driver' ? 'Fahrer' : 'Fahrzeug';
                this.opportunities.push({
                    title: `${entityType}-Optimierung`,
                    icon: this.option.id === 'driver' ? 'ri-user-line' : 'ri-car-line',
                    potential: Math.min(90, highEmptyRateEntities[0].emptyPercentage * 1.2),
                    potentialLabel: 'Potential',
                    priorityClass: 'low',
                });
            }
        },

        generateRecommendations() {
            // Simplified recommendation set with combined data
            this.recommendations = [
                {
                    title: 'Optimierung der Fahrzeugverteilung',
                    description:
                        'Mehr Fahrzeuge in den Hauptverkehrszeiten (7-9 Uhr und 17-19 Uhr) einsetzen, um die Nachfrage zu decken.',
                    priority: 'Hoch',
                    priorityClass: 'high',
                    impact: 5,
                    icon: 'ri-taxi-line',
                    potential: 85,
                    potentialLabel: 'Umsatzsteigerung',
                },
                {
                    title: 'Fahrer-Schulung für effizientere Routen',
                    description: `Schulungen für Fahrer mit hoher Leerquote könnten die Gesamtleerquote um bis zu ${Math.floor(
                        Math.random() * 5 + 8,
                    )}% senken.`,
                    priority: 'Mittel',
                    priorityClass: 'medium',
                    impact: 4,
                    icon: 'ri-route-line',
                    potential: 65,
                    potentialLabel: 'Effizienzsteigerung',
                },
                {
                    title: 'Preisgestaltung und Incentives anpassen',
                    description:
                        'In bestimmten Gebieten liegen die Fahrpreise unter dem optimalen Niveau. Eine Anpassung könnte den Umsatz pro Kilometer erhöhen.',
                    priority: 'Mittel',
                    priorityClass: 'medium',
                    impact: 3,
                    icon: 'ri-money-dollar-circle-line',
                    potential: 50,
                    potentialLabel: 'Umsatzoptimierung',
                },
            ];

            // If we have opportunity data from findOpportunities, incorporate it
            if (this.opportunities.length > 0) {
                // Find matching opportunities and add their data
                this.recommendations.forEach(rec => {
                    const matchingOpp = this.opportunities.find(
                        opp =>
                            opp.title
                                .toLowerCase()
                                .includes(rec.title.toLowerCase().split(' ')[0]) ||
                            rec.title.toLowerCase().includes(opp.title.toLowerCase().split(' ')[0]),
                    );

                    if (matchingOpp) {
                        rec.potential = matchingOpp.potential || rec.potential;
                        rec.icon = matchingOpp.icon || rec.icon;
                        rec.priorityClass = matchingOpp.priorityClass || rec.priorityClass;
                    }
                });
            }
        },
    },
    mounted() {
        this.analyzeData();
    },
};
</script>

<style lang="scss" scoped>
.business-insights {
    border-radius: 10px;
    padding: 16px;

    .insights-header {
        margin-bottom: 12px;

        h3 {
            margin: 0;
            font-size: 1.1rem;
            font-weight: 700;
            color: #1e293b;
        }
    }

    .insights-grid {
        display: flex;
        flex-direction: column;
        gap: 10px;

        h4 {
            margin: 0 0 8px;
            font-size: 0.9rem;
            font-weight: 600;
            color: #0f172a;
        }

        /* Time analysis card */
        .time-analysis-section {
            width: 100%;
            border-radius: 8px;
            box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);

            .section-header {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 8px;
            }

            .chart-loader {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 200px;
                gap: 8px;

                i.spin {
                    font-size: 1rem;
                    color: #0ea5e9;
                    animation: spin 1.5s linear infinite;
                }

                span {
                    font-size: 0.9rem;
                    color: #64748b;
                }

                @keyframes spin {
                    from {
                        transform: rotate(0deg);
                    }
                    to {
                        transform: rotate(360deg);
                    }
                }
            }
        }

        /* Integrated insights section */
        .integrated-insights-section {
            .insights-list {
                display: grid;
                grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
                gap: 10px;

                @media (max-width: 1100px) {
                    grid-template-columns: repeat(2, 1fr);
                }

                @media (max-width: 700px) {
                    grid-template-columns: 1fr;
                }

                .insight-card {
                    padding: 12px;
                    border-radius: 6px;
                    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
                    margin: 0;

                    .insight-header {
                        display: flex;
                        align-items: center;
                        gap: 10px;
                        margin-bottom: 8px;

                        .insight-icon {
                            width: 32px;
                            height: 32px;
                            min-width: 32px;
                            border-radius: 8px;
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            font-size: 1rem;
                            color: white;

                            &.high {
                                background-color: #16a34a;
                            }

                            &.medium {
                                background-color: #f59e0b;
                            }

                            &.low {
                                background-color: #0ea5e9;
                            }
                        }

                        .insight-title-area {
                            flex: 1;

                            .priority-badge {
                                display: inline-block;
                                padding: 2px 6px;
                                border-radius: 4px;
                                font-size: 0.65rem;
                                font-weight: 600;
                                margin-bottom: 3px;

                                &.high {
                                    background-color: rgba(220, 38, 38, 0.1);
                                    color: #dc2626;
                                }

                                &.medium {
                                    background-color: rgba(245, 158, 11, 0.1);
                                    color: #f59e0b;
                                }

                                &.low {
                                    background-color: rgba(22, 163, 74, 0.1);
                                    color: #16a34a;
                                }
                            }

                            .insight-title {
                                font-weight: 600;
                                font-size: 1rem;
                                line-height: 1.3;
                            }
                        }
                    }

                    .insight-content {
                        padding-left: 42px;

                        p {
                            margin: 0 0 12px 0;
                            font-size: 0.85rem;
                            color: #334155;
                            line-height: 1.4;
                        }

                        .metrics-row {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            gap: 16px;
                            margin-top: 6px;

                            .potential-section {
                                flex: 1;
                                display: flex;
                                flex-direction: column;
                                gap: 4px;

                                .potential-bar {
                                    width: 100%;
                                    height: 4px;
                                    background-color: #e2e8f0;
                                    border-radius: 3px;
                                    overflow: hidden;
                                    margin-bottom: 4px;

                                    .potential-value {
                                        height: 100%;
                                        border-radius: 3px;

                                        &.high {
                                            background-color: #16a34a;
                                        }

                                        &.medium {
                                            background-color: #f59e0b;
                                        }

                                        &.low {
                                            background-color: #0ea5e9;
                                        }
                                    }
                                }

                                .potential-label {
                                    font-size: 1rem;
                                    display: flex;
                                    justify-content: space-between;
                                    align-items: center;

                                    strong {
                                        color: #0f172a;
                                    }
                                }
                            }

                            .impact-section {
                                display: flex;
                                flex-direction: column;
                                gap: 4px;
                                min-width: 70px;

                                .impact-label {
                                    font-size: 0.65rem;
                                    color: #64748b;
                                    margin-bottom: 2px;
                                }

                                .impact-indicator {
                                    display: flex;
                                    gap: 3px;

                                    .impact-dot {
                                        width: 8px;
                                        height: 8px;
                                        border-radius: 50%;
                                        background-color: #e2e8f0;

                                        &.filled {
                                            background-color: #0ea5e9;
                                        }

                                        &.impact-high {
                                            background-color: #16a34a;
                                        }

                                        &.impact-medium {
                                            background-color: #f59e0b;
                                        }

                                        &.impact-low {
                                            background-color: #0ea5e9;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
