var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "activity-heatmap"
  }, [_c('div', {
    staticClass: "heatmap-header"
  }, [_c('h3', [_vm._v("Aktivitäts-Heatmap")]), _c('div', {
    staticClass: "metric-toggle"
  }, _vm._l(_vm.metrics, function (metric) {
    return _c('Button', {
      key: metric.id,
      class: ['metric-button', _vm.selectedMetric === metric.id ? 'active' : ''],
      attrs: {
        "size": "small"
      },
      on: {
        "onClick": function onClick() {
          return _vm.setMetric(metric.id);
        }
      }
    }, [_vm._v(" " + _vm._s(metric.label) + " ")]);
  }), 1)]), _c('div', {
    ref: "heatmapContainer",
    staticClass: "heatmap-container"
  }, [_vm.loading ? _c('div', {
    staticClass: "chart-loader"
  }, [_c('i', {
    staticClass: "ri-loader-4-line spin"
  }), _c('span', [_vm._v("Lade Daten...")])]) : _vm._e()]), _c('div', {
    staticClass: "heatmap-insights"
  }, _vm._l(_vm.insights, function (insight, index) {
    return _c('div', {
      key: index,
      staticClass: "insight-card"
    }, [_c('div', {
      staticClass: "insight-icon"
    }, [_c('i', {
      class: insight.icon
    })]), _c('div', {
      staticClass: "insight-content"
    }, [_c('h4', [_vm._v(_vm._s(insight.title))]), _c('p', [_vm._v(_vm._s(insight.description))])])]);
  }), 0)]);
}
var staticRenderFns = []

export { render, staticRenderFns }