var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "business-insights"
  }, [_vm._m(0), _c('div', {
    staticClass: "insights-grid"
  }, [_c('div', {
    staticClass: "time-analysis-section"
  }, [_vm.isChartLoading ? _c('div', {
    staticClass: "chart-loader"
  }, [_c('i', {
    staticClass: "ri-loader-4-line spin"
  }), _c('span', [_vm._v("Lade Daten...")])]) : _c('ChartV2', {
    attrs: {
      "title": "Stündliche Aktivität",
      "type": "bar",
      "values": _vm.chartValues,
      "halfHeight": true
    }
  })], 1), _c('div', {
    staticClass: "integrated-insights-section"
  }, [_c('h4', [_vm._v("Optimierungspotential & Empfehlungen")]), _c('div', {
    staticClass: "insights-list"
  }, _vm._l(_vm.recommendations, function (rec, index) {
    return _c('Card', {
      key: index,
      staticClass: "insight-card"
    }, [_c('div', {
      staticClass: "insight-header"
    }, [_c('div', {
      staticClass: "insight-icon",
      class: rec.priorityClass
    }, [_c('i', {
      class: rec.icon || 'ri-lightbulb-line'
    })]), _c('div', {
      staticClass: "insight-title-area"
    }, [_c('div', {
      staticClass: "priority-badge",
      class: rec.priorityClass
    }, [_vm._v(" " + _vm._s(rec.priority) + " ")]), _c('div', {
      staticClass: "insight-title"
    }, [_vm._v(_vm._s(rec.title))])])]), _c('div', {
      staticClass: "insight-content"
    }, [_c('p', [_vm._v(_vm._s(rec.description))]), _c('div', {
      staticClass: "metrics-row"
    }, [_c('div', {
      staticClass: "potential-section"
    }, [_c('div', {
      staticClass: "potential-bar"
    }, [_c('div', {
      staticClass: "potential-value",
      class: rec.priorityClass,
      style: {
        width: rec.potential + '%'
      }
    })]), _c('div', {
      staticClass: "potential-label"
    }, [_c('span', [_vm._v(_vm._s(rec.potentialLabel || 'Optimierungspotential') + ":")]), _c('strong', [_vm._v(_vm._s(rec.potential) + "%")])])])])])]);
  }), 1)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "insights-header"
  }, [_c('h3', [_vm._v("Geschäftsanalyse & Empfehlungen")])]);
}]

export { render, staticRenderFns }