var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "Analytics",
    class: {
      'is-loading': _vm.isFetchingData
    }
  }, [_c('Toolbar', [_c('div', {
    staticClass: "toolbar-controls"
  }, [_c('Datepicker', {
    attrs: {
      "startDate": _vm.selectedFrom,
      "monthPicker": ""
    },
    on: {
      "onChange": _vm.handleDateChange
    }
  }), _c('Dropdown', {
    attrs: {
      "items": _vm.dropdownValues,
      "selected": _vm.selectedValue && _vm.selectedValue.value,
      "variant": "single-column",
      "hasRemoveIcon": false,
      "placeholder": "Kategorie auswählen"
    },
    on: {
      "onItemSelect": _vm.handleDropdownSelect
    }
  }), _c('Button', {
    attrs: {
      "size": "small",
      "isLoading": _vm.isFetchingData
    },
    on: {
      "onClick": _vm.handleQuery
    }
  }, [_vm._v(" Anzeigen ")])], 1), _c('div', {
    staticClass: "toolbar-actions"
  }, [_c('Download', {
    attrs: {
      "title": "Download CSV",
      "type": "csv"
    },
    on: {
      "onDownload": function onDownload($event) {
        return _vm.handleDownload('csv');
      }
    }
  })], 1)]), !_vm.isFetchingData && _vm.shifts.length > 0 ? _c('div', {
    staticClass: "OverviewCards"
  }, _vm._l(_vm.summaryMetrics, function (metric) {
    return _c('Card', {
      key: metric.id,
      staticClass: "OverviewCard"
    }, [_c('div', {
      staticClass: "metric-content"
    }, [_c('div', {
      staticClass: "metric-icon",
      class: metric.trend
    }, [_c('i', {
      class: _vm.getMetricIcon(metric)
    })]), _c('div', {
      staticClass: "metric-details"
    }, [_c('h3', [_vm._v(_vm._s(metric.label))]), _c('div', {
      staticClass: "metric-value"
    }, [_vm._v(_vm._s(metric.value))]), _c('div', {
      staticClass: "metric-change",
      class: metric.trend
    }, [_c('span', [_vm._v(_vm._s(metric.changeText))]), _c('i', {
      staticClass: "trend-icon ri-arrow-up-line",
      class: {
        'ri-arrow-down-line': metric.trend === 'negative'
      }
    })])])])]);
  }), 1) : _vm._e(), !_vm.isFetchingData && _vm.shifts.length > 0 ? _c('div', {
    staticClass: "view-container"
  }, [_vm.selectedView === 'table' ? _c('div', [_c('AnalyticsTable', {
    attrs: {
      "shifts": _vm.shifts,
      "startAt": _vm.startAt,
      "endAt": _vm.endAt,
      "option": _vm.selectedValue
    },
    on: {
      "onCVSChange": _vm.handleCSVChange,
      "onRowHover": _vm.handleRowHover,
      "onDriverClick": _vm.handleDriverClick
    }
  })], 1) : _vm.selectedView === 'performance' ? _c('div', [_c('PerformanceChart', {
    attrs: {
      "data": _vm.performanceData,
      "option": _vm.selectedValue
    }
  })], 1) : _vm.selectedView === 'trends' ? _c('div', [_c('RevenueTrends', {
    attrs: {
      "shifts": _vm.shifts,
      "startAt": _vm.startAt,
      "endAt": _vm.endAt
    }
  })], 1) : _vm.selectedView === 'heatmap' ? _c('div', [_c('ActivityHeatmap', {
    attrs: {
      "shifts": _vm.shifts,
      "startAt": _vm.startAt,
      "endAt": _vm.endAt
    }
  })], 1) : _vm.selectedView === 'insights' ? _c('div', [_c('BusinessInsights', {
    attrs: {
      "shifts": _vm.shifts,
      "startAt": _vm.startAt,
      "endAt": _vm.endAt,
      "option": _vm.selectedValue
    }
  })], 1) : _vm._e()]) : _vm._e(), !_vm.isFetchingData && _vm.shifts.length === 0 ? _c('EmptyState') : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }